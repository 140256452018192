<template>
  <div class="view-deal-dialog" ref="print">
    <el-dialog :title="tradeOrderInfoData.auctionName" center :visible.sync="dialogVisible" width="45%">
      <div class="deal-dialog-1">
        <span>成交确认书编号：{{tradeOrderInfoData.tradeNo}}</span>
        <span>{{tradeOrderInfoData.sendTime}}</span>
      </div>
      <div class="deal-dialog-2">
        <div class="deal-dialog-middle">
          <p class="w-info">用户信息</p>
          <p>
            <span>用户姓名：</span>
            <span>{{tradeOrderInfoData.realName}}</span>
          </p>
          <p>
            <span>联系电话：</span>
            <span>{{tradeOrderInfoData.userPhone}}</span>
          </p>
          <p>
            <span>收货地址：</span>
            <span>{{tradeOrderInfoData.address}}</span>
          </p>
        </div>
        <div class="deal-dialog-middle">
          <p class="w-info">拍卖行信息</p>
          <p>
            <span class="labalFive">拍卖行名称：</span>
            <span>{{tradeOrderInfoData.auctionHouseName}}</span>
          </p>
          <p>
            <span class="labalFive">拍卖行地址：</span>
            <span>{{tradeOrderInfoData.auctionHouseAddress}}</span>
          </p>
          <p>
            <span class="labalFive">拍卖行邮箱：</span>
            <span>{{tradeOrderInfoData.auctionHouseEmail}}</span>
          </p>
          <p>
            <span class="labalFive">拍卖行电话：</span>
            <span>{{tradeOrderInfoData.auctionHousePhone}}</span>
          </p>
        </div>
      </div>
      <div class="deal-dialog-3">
        <p class="w-info">费用明细</p>
        <div class="deal-dialog-3-bottom">
          <div class="deal-dialog-3-bottom-left">
            <p>
              <span>落槌价:</span>
              <span class="p-money">{{tradeOrderInfoData.dropHammerPrice}}{{tradeOrderInfoData.currency}}</span>
            </p>
            <p>
              <span>买家佣金:</span>
              <span class="p-money">{{tradeOrderInfoData.buyersPremium}}{{tradeOrderInfoData.currency}}</span>
            </p>
            <div class="tax-for" v-for="(item,index) in itemTax" :key="index">
              <p>
                <span>{{item.tax}}</span>
                <span class="p-money">{{item.money}}{{tradeOrderInfoData.currency}}</span>
                <i class="iconfont icon-cha1" @click="off(item,index)" v-if="sendShow"></i>

              </p>

            </div>
            <p v-if="sendShow === false">
              <span>TAX:</span>
              <span class="p-money">{{tradeOrderInfoData.taxRate}}{{tradeOrderInfoData.currency}}</span>
            </p>
            <p v-if="sendShow === false">
              <span>其他:</span>
              <span class="p-money">{{tradeOrderInfoData.otherPrice}}{{tradeOrderInfoData.currency}}</span>
            </p>
            <p>
              <span>拍品总价:</span>
              <span class="p-money">{{tradeOrderInfoData.totalPrice}}{{tradeOrderInfoData.currency}}</span>
            </p>

          </div>
          <div class="deal-dialog-3-bottom-right" v-if="sendShow">
            <el-select v-model="value" placeholder="TAX/其他">
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.name">
              </el-option>
            </el-select>
            <el-input v-model="input" placeholder="请输入金额"></el-input>
            <button class="p-add" @click="okAdd()">确认添加</button>
          </div>
        </div>
      </div>
      <div class="deal-dialog-4">
        <p class="w-info">付款方式</p>
        <textarea name="" id="" class="pat-tex" placeholder="请输入贵行的付款方式，填写内容会显示到您通过本站发出的账单中。"
          v-model="tradeOrderInfoData.paymentMethod"></textarea>
      </div>
      <div class="deal-dialog-4">
        <p class="w-info">运输信息</p>
        <textarea name="" id="" class="pat-tex" placeholder="请输入贵行的运输信息，填写内容会显示到您通过本站发出的账单中。"
          v-model="tradeOrderInfoData.mailingMethod"></textarea>
      </div>
      <div class="deal-dialog-5">
        <el-table :data="tradeOrderInfoData.list" style="width: 100%">
          <el-table-column prop="lot" label="Lot号">
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-image class="table-td-thumb" :src="scope.row.itemImage" :preview-src-list="[scope.row.itemImage]">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column prop="itemName" label="拍品名称">
            <template slot-scope="scope">
              <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:scope.row.id}}">
                {{scope.row.itemName}}
              </router-link>

            </template>
          </el-table-column>
          <el-table-column prop="dropHammerPrice" label="落槌价">
          </el-table-column>
          <el-table-column prop="buyersPremium" label="买家佣金">
          </el-table-column>
          <el-table-column prop="totalPrice" label="成交价">
          </el-table-column>
        </el-table>
      </div>
      <div class="deal-dialog-6">
        <div class="deal-dialog-6-left">
          <span class="r-p-count-1">拍品总价</span>
          <span class="r-p-count-2">{{tradeOrderInfoData.totalPrice}}{{tradeOrderInfoData.currency}}</span>
        </div>
        <div class="deal-dialog-6-right">
          <!-- <button class="btn-1-1" v-print="'#printTest'">打印</button> -->
          <button class="btn-1-1" @click="printTest">打印</button>
          <button class="btn-2-2" v-if="sendShow" @click="send()">确认发送</button>
          <button class="btn-3-3" @click="dialogVisible = false" v-if="sendShow">取消</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  tradeOrderInfo,
  sendTradeOrder,
  createTradeOrderInfo,
  userTradeOrderInfo
} from '../../../../../api/index'
export default {
  name: 'DealDialog',
  data () {
    return {
      dialogVisible: false,
      tableData: [],
      options: [{
        id: 1,
        name: 'TAX'
      },
      {
        id: 2,
        name: '其他'
      }
      ],
      value: '',
      input: '',
      itemTax: [],
      tradeOrderInfoData: {},
      sendTradeOrderData: {
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        outTradeNo: '',
        userId: 0,
        taxRate: '',
        otherPrice: '',
        tradeStatus: 2,
        paymentMethod: '',
        mailingMethod: '',
        auctionId: 0
      },
      sendShow: false,
      createTradeOrderInfoData: {
        userId: 0,
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        auctionId: 0,
        status: 1,
        page: 1,
        pageSize: 999
      }
    }
  },
  props: {
    confirmationLetterId: {
      type: Object
    }
  },
  created () {

  },
  methods: {
    open () {
      this.dialogVisible = true
      this.itemTax = []

      if (this.confirmationLetterId.name === '创建') {
        this.value = ''
        this.input = ''
        this.sendShow = true
        this.createTradeOrderInfoData.auctionId = this.confirmationLetterId.auctionId
        this.createTradeOrderInfoData.userId = this.confirmationLetterId.userId
        this.createTradeOrderInfo()
      } else if (this.confirmationLetterId.name === '查看') {
        this.sendShow = false

        this.tradeOrderInfo()
      } else if (this.confirmationLetterId.name === '查看User') {
        this.sendShow = false
        this.userTradeOrderInfo()
      }
    },
    // 数据初始化
    async tradeOrderInfo () {
      const res = await tradeOrderInfo({
        userId: this.confirmationLetterId.userId || Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        outTradeNo: this.confirmationLetterId.trandNo,
        page: 1,
        pageSize: 999
      })
      if (res.code === 0) {
        this.tradeOrderInfoData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 数据初始化
    async userTradeOrderInfo () {
      const res = await userTradeOrderInfo({
        userId: this.confirmationLetterId.userId || Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        outTradeNo: this.confirmationLetterId.trandNo,
        page: 1,
        pageSize: 999
      })
      if (res.code === 0) {
        this.tradeOrderInfoData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 创建数据初始化
    async createTradeOrderInfo () {
      const res = await createTradeOrderInfo(this.createTradeOrderInfoData)
      if (res.code === 0) {
        this.tradeOrderInfoData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    okAdd () {
      if (this.value === '' || this.input === '') {
        this.$message.error('请填写完整')
        return
      }
      const rep = this.tradeOrderInfoData.totalPrice
      const repN = rep.replace(/,/g, '')
      if (this.value === 'TAX') {
        const index = this.itemTax.findIndex(item => item.tax === 'TAX')
        if (index === -1) {
          this.itemTax.push({
            tax: this.value,
            money: this.input
          })
          const totalPrice = Number(this.input) + Number(repN)
          this.num_filter(totalPrice)
        } else {
          this.$message.error('TAX不能重复添加')
        }
      } else {
        const other = this.itemTax.findIndex(item => item.tax === '其他')
        if (other === -1) {
          this.itemTax.push({
            tax: this.value,
            money: this.input
          })
          this.tradeOrderInfoData.totalPrice += this.input
          const totalPrice = Number(this.input) + Number(repN)
          this.num_filter(totalPrice)
        } else {
          this.$message.error('其他不能重复添加')
        }
      }

      this.input = ''
      this.value = ''
    },
    // 千位分
    num_filter (val) {
      val = val.toLocaleString()
      if (val.indexOf('.') === -1) {
        val = val + '.00'
      } else if (val.charAt(val.indexOf('.') === val.length - 2)) {
        val = val + '0'
      }
      this.tradeOrderInfoData.totalPrice = val
    },
    // 确认发送
    async send () {
      for (let index = 0; index < this.itemTax.length; index++) {
        if (this.itemTax[index].tax === 'TAX') {
          this.sendTradeOrderData.taxRate = this.itemTax[index].money
        }
        if (this.itemTax[index].tax === '其他') {
          this.sendTradeOrderData.otherPrice = this.itemTax[index].money
        }
      }
      this.sendTradeOrderData.paymentMethod = this.tradeOrderInfoData.paymentMethod
      this.sendTradeOrderData.mailingMethod = this.tradeOrderInfoData.mailingMethod
      this.sendTradeOrderData.outTradeNo = this.confirmationLetterId.trandNo
      this.sendTradeOrderData.auctionId = this.confirmationLetterId.auctionId
      this.sendTradeOrderData.userId = this.confirmationLetterId.userId
      const res = await sendTradeOrder(this.sendTradeOrderData)
      if (res.code === 0) {
        this.dialogVisible = false
        this.$message.success('发送成功')
        this.$emit('sendOk')
      } else {
        this.$message.error(res.msg)
      }
    },
    printTest () {
      this.$print(this.$refs.print)
    },
    // 关闭
    off (item, index) {
      const rep = this.tradeOrderInfoData.totalPrice
      const repN = rep.replace(/,/g, '')
      const totalPrice = Number(repN) - Number(item.money)
      this.num_filter(totalPrice)
      this.itemTax.splice(index, 1)
    }
  }
}
</script>
<style lang="less" scoped>
  .view-deal-dialog {
    /deep/ .el-dialog__title {
      font-weight: bold;
      font-size: 24px;
    }

    /deep/ .el-dialog__headerbtn .el-dialog__close {
      display: none;
    }

    .w-info {
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 200 !important;
    }

    .deal-dialog-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 6px;
      border-bottom: 1px solid #dedede;

      span {
        font-size: 16px;
        color: #333;
      }
    }

    .deal-dialog-2 {
      display: flex;
      justify-content: space-between;
      //align-items: center;
      margin-top: 10px;

      .deal-dialog-middle {
        border: 1px solid #dedede;
        padding: 20px 19px 0 19px;
        width: 390px;
        // height: 230px;
        text-align: left;

        p {
          margin-bottom: 14px;
          color: #333;
          font-weight: 400;
          font-size: 16px;
          display: flex;

          >.labalFive {
            width: auto !important;
          }

          >span {
            &:first-child {
              width: 100px;
              white-space: nowrap;
            }

            &:nth-child(2) {
              flex: 1;
            }
          }
        }

        .w-info {
          font-size: 16px;
          font-weight: 600;
          color: #333;
        }
      }
    }

    .deal-dialog-3 {
      border: 1px solid #dedede;
      padding: 20px 0;
      margin-top: 20px;

      p {
        text-align: left;
        padding: 0 19px;
      }

      .deal-dialog-3-bottom {
        display: flex;
        justify-content: space-between;

        .deal-dialog-3-bottom-left {
          width: 390px;
          padding: 0 19px;
          border: 1px solid transparent;

          p {
            font-size: 18px;
            font-weight: 400;
            color: #333;
            border-bottom: 1px solid #dedede;
            padding: 10px 0;
            margin-bottom: 0;
            display: flex;
            // align-items: center;

            span {
              font-size: 16px;

              &:first-child {
                width: 100px;
                white-space: nowrap;
              }

              &:last-child {
                flex: 1;
              }
            }
          }

          .tax-for {
            // display: flex;
            // align-items: center;
          }

          .p-money {
            width: max-content;
            float: right;
          }
        }

        .deal-dialog-3-bottom-right {
          display: flex;
          justify-content: center;

          /deep/ .el-input__inner {
            width: 140px;
            height: 30px;
          }

          .p-add {
            width: 140px;
            height: 30px;
            background: #fff;
            border-radius: 8px;
            border: 1px solid #cda156;
            text-align: center;
            line-height: 30px;
            color: #cda156;
            font-size: 16px;
          }
        }
      }
    }

    .deal-dialog-4 {
      border: 1px solid #dedede;
      padding: 20px;
      margin-top: 20px;
      height: 160px;
      overflow: hidden;

      p {
        text-align: left;
      }

      .pat-tex {
        width: 100%;
        height: 100%;
        resize: none;
        border: none;
        outline: none;
      }
    }

    .deal-dialog-5 {
      padding: 20px 0;

      /deep/ .el-table th.el-table__cell {
        background: #f5edde;
        color: #333;
        font-size: 16px;
      }
    }

    .deal-dialog-6 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .deal-dialog-6-left {
        font-size: 18px;
        font-weight: 600;

        .r-p-count-1 {
          color: rgb(94, 94, 94);
        }

        .r-p-count-2 {
          color: rgb(212, 0, 24);
          padding-left: 5px;
        }
      }

      .deal-dialog-6-right {
        button {
          width: 100px;
          height: 42px;
          border-radius: 8px;
          text-align: center;
          line-height: 42px;
          border: none;
          font-size: 18px;
        }

        .btn-1-1 {
          color: #cda156;
          border: 1px solid #cda156;
          background: #fff;
        }

        .btn-2-2 {
          background: #cda156;
          color: #fff;
          margin: 0 10px;
        }

        .btn-3-3 {
          color: #999;
          border: 1px solid #999999;
          background: #fff;
        }
      }
    }
  }
</style>
