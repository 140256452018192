<template>
  <div class="addReceivinggoods-view">
    <el-dialog title="收货地址" :visible.sync="dialogVisible" width="80%">
      <div class="attestation-view-from">
        <div class="attestation-view-from-1">
          <span style="color:#CD5656;">*</span>
          <span>收货姓名：</span>
          <input type="text" placeholder="请输入您的姓名" v-model="addReceivinggoods.realName">
        </div>
        <div class="attestation-view-from-1">
          <span style="color:#CD5656;">*</span>
          <span>手机号码：</span>
          <input type="text" placeholder="请输入您的手机号码" v-model="addReceivinggoods.mobile">
        </div>
        <div class="attestation-view-from-1">
          <span style="color:#CD5656;">*</span>
          <span>邮政编码：</span>
          <input type="text" placeholder="请输入您的邮政编码" v-model="addReceivinggoods.zipCode">
        </div>
        <div class="attestation-view-from-1">
          <span style="color:#CD5656;">*</span>
          <span>所在地区：</span>
          <el-select v-model="value" placeholder="请选择" @change="handleChange">
            <el-option v-for="item in areaListData" :key="item.code" :label="item.name"
              :value="{value:item.code,label:item.name}">
            </el-option>
          </el-select>
          <el-select v-model="value2" placeholder="请选择" @change="provincesChange" v-if="selectShow">
            <el-option v-for="item in provinces" :key="item.code" :label="item.name"
              :value="{value:item.code,label:item.name}">
            </el-option>
          </el-select>
          <el-select v-model="value3" placeholder="请选择" @change="rbanAreaChange" v-if="selectShow">
            <el-option v-for="item in rbanArea" :key="item.code" :label="item.name"
              :value="{value:item.code,label:item.name}">
            </el-option>
          </el-select>
          <el-select v-model="value4" placeholder="请选择" @change="countySeatChange" v-if="selectShow">
            <el-option v-for="item in countySeat" :key="item.code" :label="item.name"
              :value="{value:item.code,label:item.name}">
            </el-option>
          </el-select>
          <el-select v-model="value5" placeholder="请选择" @change="villagesChange" v-if="selectShow">
            <el-option v-for="item in villages" :key="item.code" :label="item.name"
              :value="{value:item.code,label:item.name}">
            </el-option>
          </el-select>
          <!-- <input type="text" placeholder="请输入您的所在地址(以国别开头)" v-model="addReceivinggoods.addressDetails"> -->
        </div>
        <div class="attestation-view-from-1 flex-1">
          <span style="color:#CD5656;">*</span>
          <span>详细地址：</span>
          <textarea placeholder="请输入您的详细地址" v-model="addReceivinggoods.addressDetails"></textarea>
          <!-- <input type="textarea" placeholder="请输入您的详细地址"> -->
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="addReceivinggoodsSave()">新 增</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addAddress, areaList } from '../../../api/index'
export default {
  data () {
    return {
      dialogVisible: false,
      addReceivinggoods: {
        addressDetails: '',
        zipCode: '',
        areaCode: '',
        realName: '',
        mobile: '',
        userid: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      },
      value: '',
      value2: '',
      value3: '',
      value4: '',
      value5: '',
      areaListData: [],
      provinces: [],
      rbanArea: [],
      countySeat: [],
      villages: [],
      areaListDataText: '',
      rbanAreaText: '',
      countySeatText: '',
      villagesText: '',
      lastText: '',
      selectShow: true
    }
  },
  inject: ['reload'],
  watch: {
    // value: {
    //   handler (newV, oldV) {
    //     if (newV.label !== oldV.label) {
    //       this.value2 = ''
    //       this.value3 = ''
    //       this.value4 = ''
    //       this.value5 = ''
    //     }
    //   }
    // },
    value2: {
      handler (newV, oldV) {
        if (newV.label !== oldV.label) {
          this.value3 = ''
          this.value4 = ''
          this.value5 = ''
        }
      }
    },
    value3: {
      handler (newV, oldV) {
        if (newV.label !== oldV.label) {
          this.value4 = ''
          this.value5 = ''
        }
      }
    },
    value4: {
      handler (newV, oldV) {
        if (newV.label !== oldV.label) {
          this.value5 = ''
        }
      }
    }
  },
  methods: {
    open () {
      this.dialogVisible = true
      this.addReceivinggoods.addressDetails = ''
      this.addReceivinggoods.zipCode = ''
      this.addReceivinggoods.realName = ''
      this.addReceivinggoods.mobile = ''
      this.value = ''
      this.value2 = ''
      this.value3 = ''
      this.value4 = ''
      this.value5 = ''
      this.areaListData = []
      this.provinces = []
      this.rbanArea = []
      this.countySeat = []
      this.villages = []
      this.selectShow = true
      this.areaList()
    },
    // 新增
    async addReceivinggoodsSave () {
      if (this.addReceivinggoods.realName === '') {
        this.$message.error('收货姓名不能为空')
        return
      } else if (this.addReceivinggoods.mobile === '') {
        this.$message.error('手机号不能为空')
        return
      } else if (this.addReceivinggoods.zipCode === '') {
        this.$message.error('邮政编码不能为空')
        return
      } else if (this.value === '') {
        this.$message.error('国别不能为空')
        return
      } else if (this.addReceivinggoods.addressDetails === '') {
        this.$message.error('详细地址不能为空')
        return
      }
      this.addReceivinggoods.areaCode = this.areaListDataText + this.rbanAreaText + this.countySeatText + this.villagesText + this.lastText
      if (this.rbanAreaText === '重庆') {
        if (this.countySeatText === '县') {
          this.addReceivinggoods.areaCode = this.areaListDataText + this.rbanAreaText + this.villagesText + this.lastText
        }
      }
      const res = await addAddress(this.addReceivinggoods)
      if (res.code === 0) {
        this.$message.success('新增成功')
        this.dialogVisible = false
        this.$emit('addReceivinggoodsSave')
      } else {
        this.$message.error(res.msg)
      }
    },
    // 地区数据
    async areaList () {
      const res = await areaList({
        code: ''
      })
      if (res.code === 0) {
        this.areaListData = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    // 选择地区
    async handleChange (params) {
      const { value, label } = params
      if (label === '港澳台及海外地区') {
        this.selectShow = false
      } else {
        this.selectShow = true
      }
      this.areaListDataText = label
      const res = await areaList({
        code: value
      })
      if (res.code === 0) {
        this.provinces = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    async provincesChange (params) {
      const { value, label } = params
      this.rbanAreaText = label
      const res = await areaList({
        code: value
      })
      if (res.code === 0) {
        this.rbanArea = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    async rbanAreaChange (params) {
      const { value, label } = params
      this.countySeatText = label
      const res = await areaList({
        code: value
      })
      if (res.code === 0) {
        this.countySeat = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    async countySeatChange (params) {
      const { value, label } = params
      this.villagesText = label
      const res = await areaList({
        code: value
      })
      if (res.code === 0) {
        this.villages = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    villagesChange (params) {
      const { value, label } = params
      this.lastText = label
    }
  }
}
</script>
<style lang="less" scoped>
.addReceivinggoods-view {
  /deep/ .el-dialog__title {
    font-size: 24px;
    color: #333;
    font-weight: 600;
  }
  /deep/ .el-dialog__header {
    text-align: center;
    padding-top: 40px;
  }
  /deep/ .el-dialog__footer {
    text-align: center;
  }
  /deep/ .el-button {
    width: 140px;
    height: 50px;
    background: #f5edde;
    border-radius: 5px;
    color: #6e5121;
    font-weight: 600;
    border-color: #f5edde;
    font-size: 20px;
  }
  /deep/ .el-select {
    margin-right: 10px;
  }
  .attestation-view-from {
    margin-top: 60px;
    padding-left: 90px;
    span {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
    input {
      width: 400px;
      height: 60px;
      color: #999;
      padding-left: 5px;
      font-size: 20px;
      border: 1px solid #dedede;
      padding-left: 20px;
    }
    .attestation-view-from-1 {
      margin-bottom: 30px;

      textarea {
        resize: none;
        outline: none;
        width: 320px;
        height: 80px;
        color: #999;
        font-size: 20px;
        border: 1px solid #dedede;
        padding-left: 20px;
      }
    }
    .flex-1 {
      display: flex;
    }
  }
}
</style>
