<template>
  <div class="attestation-view">
    <div class="success-ok" v-if="successOk">
      <h1>实名认证</h1>
      <div class="attestation-view-from">
        <div class="attestation-view-from-1">
          <span style="color:#CD5656;">*</span>
          <span>真实姓名：</span>
          <input type="text" placeholder="请输入您的真实姓名" v-model="realNameAuthenticationData.name">
        </div>
        <div class="attestation-view-from-2">
          <span style="color:#CD5656;">*</span>
          <span>证件号码：</span>
          <input type="text" placeholder="请输入您正确的证件号码" v-model="realNameAuthenticationData.idCard">
        </div>
      </div>
      <a href="javascript:;" class="attestation-view-from-ok" @click="attestation">
        确认
      </a>
    </div>
    <div class="succ-ok-img" v-else>
      <div class="succ-ok-img-left">
        <img src="../../../assets/images/duigou@2x.png" alt="">
        <span class="green-ok">您已实名认证成功</span>
      </div>
      <div class="succ-ok-img-right">
        <p>
          <span class="wight">真实姓名：</span>
          <span>{{realName}}</span>
        </p>
        <p>
          <span class="wight">证件号码：</span>
          <span>{{idCard}}</span>
        </p>
        <p>
          <span class="wight">证件类型：</span>
          <span>居民身份证</span>
        </p>
      </div>
    </div>
  </div>

</template>
<script>
import { realNameAuthentication, userInfo } from '../../../api/index'
export default {
  data () {
    return {
      realNameAuthenticationData: {
        name: '',
        idCard: '',
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      },
      successOk: true,
      realName: '',
      idCard: ''

    }
  },
  created () {
    if (JSON.parse(localStorage.getItem('attestation')).status !== 2) {
      this.successOk = true
    } else {
      this.successOk = false
      this.userInfo()
    }
  },
  methods: {
    // 确认
    async attestation () {
      if (this.realNameAuthenticationData.name === '') {
        this.$message.error('真实姓名不能为空')
        return
      }
      if (this.realNameAuthenticationData.idCard === '') {
        this.$message.error('证件号码不能为空')
        return
      }
      const idcardReg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/

      if (idcardReg.test(this.realNameAuthenticationData.idCard)) {
        const res = await realNameAuthentication(this.realNameAuthenticationData)
        if (res.data.code === 200) {
          this.$message.success('认证成功')
          this.successOk = false
          this.userInfo()
        } else if (res.code === 101) {
          this.$message.error(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      } else {
        this.$message.error('身份证号格式错误')
      }
    },
    // 获取用户信息
    async userInfo () {
      const res = await userInfo({
        userid: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })
      if (res.code === 0) {
        this.realName = res.data.realName
        this.idCard = res.data.idCard
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.attestation-view {
  width: 1400px;
  height: 871px;
  background: #fff;
  // margin-top: 20px;
  padding-left: 70px;
  padding-top: 40px;
  h1 {
    font-size: 36px;
    font-weight: 600;
    color: #333;
  }
  .attestation-view-from {
    margin-top: 60px;
    span {
      font-size: 20px;
      font-weight: 600;
      color: #333;
    }
    input {
      width: 400px;
      height: 60px;
      color: #999;
      padding-left: 5px;
      font-size: 20px;
      border: 1px solid #dedede;
      padding-left: 20px;
    }
    .attestation-view-from-1 {
      margin-bottom: 30px;
    }
  }
  .attestation-view-from-ok {
    width: 260px;
    height: 50px;
    background: #cda156;
    border-radius: 8px;
    color: #fff;
    display: inline-block;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: 50px;
    margin-left: 110px;
    margin-top: 40px;
  }
  .succ-ok-img {
    width: 712px;
    height: 239px;
    border: 1px solid #dedede;
    display: flex;
    padding-left: 70px;
    padding-bottom: 44px;
    padding-top: 44px;
    .succ-ok-img-left {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 101px;
      }
      .green-ok {
        font-size: 24px;
        font-weight: 600;
        color: #60c257;
      }
    }
    .succ-ok-img-right {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      margin-left: 60px;
      .wight {
        font-weight: 600;
      }
    }
  }
}
</style>
