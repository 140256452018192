import { render, staticRenderFns } from "./Editphone.vue?vue&type=template&id=2ec98177&scoped=true&"
import script from "./Editphone.vue?vue&type=script&lang=js&"
export * from "./Editphone.vue?vue&type=script&lang=js&"
import style0 from "./Editphone.vue?vue&type=style&index=0&id=2ec98177&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec98177",
  null
  
)

export default component.exports