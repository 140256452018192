<template>
  <div class="container-recommendedIteright">
    <div class="acount-view-middle">
      <div class="acount-view-middle-left-10">
        <div class="acount-view-middle-1 br">
          <a href="javascript:;" :class="[getAccountDetailsIndex === 0?'active':'']"
            @click="getAccountDetails(0)">拍品</a>
        </div>
        <div class="acount-view-middle-1 br ">
          <a href="javascript:;" :class="[getAccountDetailsIndex === 1?'active':'']"
            @click="getAccountDetails(1)">拍卖会</a>
        </div>
        <div class="acount-view-middle-1 br">
          <a href="javascript:;" :class="[getAccountDetailsIndex === 2?'active':'']"
            @click="getAccountDetails(2)">拍卖行</a>
        </div>
      </div>
      <div class="acount-view-middle-right-10" v-if="getAccountDetailsIndex !== 2">
        <a href="javascript:;" class="right-cad156" v-if="getAccountDetailsIndex === 0"
          :class="[getAccountDetailsIndexColor === 3?'active':'']" @click="sifting(3)">即将开拍</a>
        <a href="javascript:;" class="right-cad156" v-if="getAccountDetailsIndex === 1"
          :class="[getAccountDetailsIndexColor === 3?'active':'']" @click="sifting(3)">即将开拍</a>
        <span class="line-ss">|</span>
        <a href="javascript:;" class="right-cad156" :class="[getAccountDetailsIndexColor === 4?'active':'']"
          @click="sifting(4)">已结束</a>

      </div>
    </div>
    <!-- 拍品 -->
    <div class="acount-view-bottom" v-for="(item,index) in userCollectDataList" :key="index"
      v-show="getAccountDetailsShow === 0">
      <div class="recommendedIteright-text-1">
        <div class="recommendedIteright-text-left">
          <router-link target="_blank" class="p1"
            :to="{path:'/auctiondetails',query:{auctiondetailsId:item.auctionId}}">
            {{item.auctionName}}
          </router-link>
          <!-- <a href="javascript:;" class="p1" @click="titleAuction(item.auctionId)">{{item.auctionName}}</a> -->
          <router-link target="_blank" class="p1 p2"
            :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.auctionHouseId}}">
            {{item.auctionHouseName}}
          </router-link>
          <!-- <a href="javascript:;" class="p1 p3"
            @click="auctionhousedetails(item.auctionHouseId)">{{item.auctionHouseName}}</a> -->
          <p class="p2">
            <span>{{item.auctionStartTime}}</span>
            <span class="line-text-1">|</span>
            <span>北京时间</span>
          </p>
        </div>
        <div class="recommendedIteright-text-right" v-if="getAccountDetailsIndexColor === 3 && item.auctionStatus ===1">
          预约出价中</div>
        <div class="recommendedIteright-text-right" v-if="getAccountDetailsIndexColor === 3 && item.auctionStatus ===2"
          style="background: rgba(209,0,0,.69);color:#fff;font-weight:600">
          直播中</div>
        <div class="recommendedIteright-text-right" v-if="getAccountDetailsIndexColor === 3 && item.auctionStatus ===3">
          顺延中</div>
        <div class="recommendedIteright-text-right" v-if="getAccountDetailsIndexColor === 3 && item.auctionStatus ===4"
          style="background: rgba(222,222,222,.69);">
          已结束</div>
        <div class="recommendedIteright-text-right-222" v-else-if="getAccountDetailsIndexColor === 4">已结束</div>
      </div>

      <div class="recommendedIteright-text-bottom" v-if="listPD===index">
        <div class="recommendedIteright-text-bottom-k-2" v-for="(item,index) in pageList" :key="index">
          <div class="top-img-5">
            <div class="col-md-3">
              <div class="thumb-container">
                <div class="thumb">
                  <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
                    <!-- <a href="javascript:;"> -->
                    <img :src="item.hostImage">
                    <!-- </a> -->
                  </router-link>
                </div>
              </div>
            </div>
            <i :class="[item.status === true?'iconfont icon-aixin ':'iconfont icon-kongaixin']"
              @click="qxCollot(item.id)"></i>
            <div class="status-item" v-if="item.itemAuctionStatus === 3" style="background: #CDA156;">成交</div>
            <div class="status-item" v-if="item.itemAuctionStatus === 4" style="background: #BFC3C4;color: #666666;">
              流拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 5" style="background: #BFC3C4;color: #666666;">
              撤拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 2">直播中</div>
            <div class="status-item" style="background: #CDA156;" v-if="item.itemAuctionStatus === 1">待拍</div>

            <!-- <div class="status-item" v-if="item.itemAuctionStatus === 3" style="background: #CDA156;">成交</div>
          <div class="status-item" v-if="item.itemAuctionStatus === 4" style="background: #BFC3C4;color: #666666;">流拍
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 5" style="background: #BFC3C4;color: #666666;">撤拍
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 2">直播中</div>
          <div class="status-item" style="background: #CDA156;" v-if="item.itemAuctionStatus === 1">待拍</div> -->
          </div>

          <div class="top-img-5-bottom">
            <router-link target="_blank" class="lot-h" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
              Lot:{{item.lot}}:{{item.title}}
            </router-link>
            <!-- @click="detailsGo(item.id)" -->
            <!-- <a :href="item.id" target="_blank" class="lot-h" :title="item.title">Lot:{{item.lot}}:{{item.title}}</a> -->
            <span class="ppgj-price" style="padding-bottom:10px;">拍品估价：{{item.assess}}</span>
            <span class="qpjg-price" v-if="item.nowPrice === ''">起拍价格：{{item.initialPrice}}{{item.currency}}</span>
            <span class="qpjg-price" v-else>当前价格：{{item.nowPrice}}{{item.currency}}</span>
          </div>
        </div>
      </div>

      <div class="recommendedIteright-text-bottom" v-else-if="listPD !==index">
        <div class="recommendedIteright-text-bottom-k-2" v-for="(item,index) in item.list" :key="index">
          <div class="top-img-5">
            <div class="col-md-3">
              <div class="thumb-container">
                <div class="thumb">
                  <router-link target="_blank" class="lot-h"
                    :to="{path:'/productdetails',query:{productdetailsId:item.id}}">

                    <!-- <a :href="item.id" target="_blank"> -->
                    <img :src="item.hostImage">
                    <!-- </a> -->
                  </router-link>
                </div>
              </div>
            </div>

            <i :class="[item.status === true?'iconfont icon-aixin ':'iconfont icon-kongaixin']"
              @click="qxCollot(item.id)"></i>
            <div class="status-item" v-if="item.itemAuctionStatus === 3" style="background: #CDA156;">成交</div>
            <div class="status-item" v-if="item.itemAuctionStatus === 4" style="background: #BFC3C4;color: #666666;">
              流拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 5" style="background: #BFC3C4;color: #666666;">
              撤拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 2">直播中</div>
            <div class="status-item" style="background: #CDA156;" v-if="item.itemAuctionStatus === 1">待拍</div>

            <!-- <div class="status-item" v-if="item.itemAuctionStatus === 3" style="background: #CDA156;">成交</div>
          <div class="status-item" v-if="item.itemAuctionStatus === 4" style="background: #BFC3C4;color: #666666;">流拍
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 5" style="background: #BFC3C4;color: #666666;">撤拍
          </div>
          <div class="status-item" v-if="item.itemAuctionStatus === 2">直播中</div>
          <div class="status-item" style="background: #CDA156;" v-if="item.itemAuctionStatus === 1">待拍</div> -->
          </div>

          <div class="top-img-5-bottom">
            <router-link target="_blank" class="lot-h" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
              Lot:{{item.lot}}:{{item.title}}
            </router-link>
            <!-- <a :href="item.id" target="_blank" class="lot-h" :title="item.title">Lot:{{item.lot}}:{{item.title}}</a> -->
            <span class="ppgj-price" style="padding-bottom:10px;">拍品估价：{{item.assess}}</span>
            <span class="qpjg-price"
              v-if="item.nowPrice === '' && item.itemAuctionStatus !== 3">起拍价格：{{item.initialPrice}}{{item.currency}}</span>
            <span class="qpjg-price"
              v-else-if="item.nowPrice !== '' && item.itemAuctionStatus !== 3">当前价格：{{item.nowPrice}}{{item.currency}}</span>
            <span class="qpjg-price"
              v-else-if="item.nowPrice !== '' && item.itemAuctionStatus === 3">落槌价：{{item.nowPrice}}{{item.currency}}</span>
            <span class="qpjg-price"
              v-else-if="item.nowPrice === '' && item.itemAuctionStatus === 3">落槌价：{{item.initialPrice}}{{item.currency}}</span>
          </div>
        </div>
      </div>
      <div class="pagination" @click="auctionPage(item.auctionId,index)"
        v-if="listPD===index || pageListId === item.auctionId">
        <el-pagination background layout="prev, pager, next" :total="item.count"
          :current-page="collectItemlistData.page" @current-change="handlePageChange"
          :page-size="collectItemlistData.pageSize">
        </el-pagination>
      </div>
      <div class="pagination" @click="auctionPage(item.auctionId,index)"
        v-else-if="listPD!==index && pageListId !== item.auctionId">
        <el-pagination background layout="prev, pager, next" :total="item.count" :current-page="collectItemlistDataPage"
          @current-change="handlePageChange" :page-size="collectItemlistDataPageSize">
        </el-pagination>
      </div>
    </div>
    <div class="pagination" style="text-align:center;" v-show="getAccountDetailsShow === 0">
      <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="userCollectData.page"
        :page-size="userCollectData.pageSize" @current-change="handlePageChangeP">
      </el-pagination>
    </div>
    <!-- 拍卖行 -->
    <div class="auctionName-view" v-show="getAccountDetailsShow === 2">
      <div class="auctionName-view-for" v-for="(item,index) in collectAuctionHouseListDataL" :key="index">
        <div class="auctionName-view-for-img">
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <a href="javascript:;">
                  <img :src="item.logo">
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="auctionName-view-for-text">
          <router-link target="_blank" class="auctionName-view-for-text-top"
            :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.id}}">
            {{item.name}}
          </router-link>
          <!-- <a href="javascript:;" class="auctionName-view-for-text-top"
            @click="collectAuctionHouseListGo(item.id)">{{item.name}}</a> -->
          <div class="auctionName-view-for-text-bottom" @click="qxAuctionName(item.id)">
            <a href="javascript:" class="ax-bo"
              :class="[item.status === 1?'iconfont icon-aixin' :'iconfont icon-kongaixin']"
              style="color:rgb(234, 49, 74)"></a>
            <a href="javascript:;" class="bo-tx">已收藏</a>
          </div>
        </div>
      </div>

    </div>
    <div class="pagination" style="text-align:center;" v-show="getAccountDetailsShow === 2">
      <el-pagination background layout="prev, pager, next" :total="pageTotalTwo"
        :current-page="collectAuctionHouseListData.page" :page-size="collectAuctionHouseListData.pageSize"
        @current-change="handlePageChangeX">
      </el-pagination>
    </div>
    <!-- 拍卖行结束 -->
    <!-- 拍卖会 -->
    <div class="bidding-main" v-show="getAccountDetailsShow === 1">
      <div class="bidding-main-for" v-for="(item,index) in collectAucetionListData" :key="index">
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <router-link target="_blank" class="lot-h"
                :to="{path:'/auctiondetails',query:{auctiondetailsId:item.id}}">

                <!-- <a href="javascript:;" @click="auctiondetails(item.id)"> -->
                <img :src="item.image" alt="">
                <!-- </a> -->
              </router-link>

            </div>
          </div>
        </div>
        <div class="bidding-main-right">
          <div class="bidding-main-right-left">
            <div class="bidding-collot">
              <router-link target="_blank" class="lot-h"
                :to="{path:'/auctiondetails',query:{auctiondetailsId:item.id}}">
                {{item.chineseTitleAuction}}
              </router-link>
              <!-- <a href="javascript:;" :title="item.chineseTitleAuction" @click="titleAuction(item.id)">
                {{item.chineseTitleAuction}}
              </a> -->
              <a class="right-collot" @click="biddingQx(item.id)">
                <i class="iconfont " :class="[item.status ===1?'iconfont icon-aixin':'iconfont icon-kongaixin']"
                  style="color:rgb(234, 49, 74)"></i>
                <a href="javascript:;" class="click-collot">已收藏</a>
              </a>

              <a class="right-collot" style="width:90px" @click="remind(item.id)"
                v-if="item.overD !== undefined && item.auctionWay === 8">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:rgb(234, 49, 74);font-size:17px;"></i>
                <a href="javascript:;" class="click-collot">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a>
              <a class="right-collot" style="width:90px" @click="remind(item.id)"
                v-if="item.overD1 !== undefined && item.auctionWay === 7">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:rgb(234, 49, 74);font-size:17px;"></i>
                <a href="javascript:;" class="click-collot">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a>
              <!-- <a class="right-collot-border-ss" style="width:90px" v-else>
                <i class="iconfont icon-duanxintixing " style="color:#999;;font-size:17px;"></i>
                <a href="javascript:;" class="click-collot">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a> -->
            </div>
            <router-link target="_blank" class="lot-h" style="color:#2c3e50;"
              :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.auctionHouseId}}">
              {{item.auctionHouseTitle}}
            </router-link>
            <!-- <a href="javascript:;" :title="item.auctionHouseTitle" @click="auctionhousedetails(item.auctionHouseId)"
              style="color:#2c3e50;">{{item.auctionHouseTitle}}</a> -->
            <!-- v-if="item.deferment === false" -->
            <p v-if="item.deferment === false">
              <span>{{item.startTime.replace(/\//g, '-')}}</span>
              <span>|</span>
              <span>北京时间</span>
            </p>
            <a href="javascript:;"
              style="color:#333;font-size:0.104167rem;display:block;padding-bottom:0.104167rem;text-decoration:underline"
              v-else>延期待定</a>
            <!-- <p v-else>延迟待定</p> -->
            <p class="yeelow">
              <span>保证金 {{item.earnestMoney}}</span>
              <span style="padding:0 10px">|</span>
              <span v-if="item.auctionWay === 7" style="color:#CD5656;">直播专场</span>
              <span v-else-if="item.auctionWay === 8" style="color:#cda156;">代拍专场</span>
            </p>
            <p>
              <img src="../../../assets/images/12.png" alt="">
              <span>{{item.location}}</span>
            </p>
          </div>
          <div class="right-browse">
            <span href="javascript:;" class="bidding-main-right-right"
              v-if="getAccountDetailsIndexColor===3&& item.auctionStatus === 1">预约出价中</span>
            <span href="javascript:;" class="bidding-main-right-right"
              v-if="getAccountDetailsIndexColor===3&& item.auctionStatus === 2"
              style="background: #D10000;color:#fff;font-weight:600">直播中</span>
            <span href="javascript:;" class="bidding-main-right-right"
              v-if="getAccountDetailsIndexColor===3&& item.auctionStatus === 3">顺延中</span>
            <span href="javascript:;" class="bidding-main-right-right"
              v-if="getAccountDetailsIndexColor===3&& item.auctionStatus === 4" style="background: #DEDEDE;">已结束</span>
            <span href="javascript:;" class="bidding-main-right-right bidding-main-right-right-col"
              v-else-if="getAccountDetailsIndexColor === 4">已结束</span>
            <div class="end-sss" v-if="getAccountDetailsIndexColor !== 4">
              <p class="cut-off" style="margin-bottom:0;"
                v-if="item.overD && item.auctionWay === 8 && item.deferment === false">
                {{item.endTime.replace(/\//g, '-')}}委托截止
              </p>
              <p class="cut-off pd-off"
                v-if="item.overD !== undefined && item.auctionWay === 8 && item.auctionStatus === 1 && item.overD !== -1 && item.deferment === false">
                <i style="color:rgb(197, 80, 86);font-style:normal;">{{item.overD}}</i>天<i
                  style="color:rgb(197, 80, 86);font-style:normal;">{{item.overH}}</i>时<i
                  style="color:rgb(197, 80, 86);font-style:normal;">{{item.overM}}</i>分<i
                  style="color:rgb(197, 80, 86);font-style:normal;">{{item.overS}}</i>秒
              </p>
              <p class="cut-off pd-off"
                v-if="item.overD1 !== undefined && item.auctionWay === 7 && item.auctionStatus === 1 &&item.overD1 !== -1 && item.deferment === false">
                <i style="font-style:normal">直播倒计时:</i>
                <i style="color:rgb(197, 80, 86);font-style:normal;">{{item.overD1}}</i>天<i
                  style="color:rgb(197, 80, 86);font-style:normal;">{{item.overH1}}</i>时<i
                  style="color:rgb(197, 80, 86);font-style:normal;">{{item.overM1}}</i>分<i
                  style="color:rgb(197, 80, 86);font-style:normal;">{{item.overS1}}</i>秒
              </p>

              <span class="cut-off pd-off"
                v-else-if="item.overD === undefined && item.auctionWay === 8 && item.deferment === false">委托已结束</span>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination" style="text-align:center;" v-show="getAccountDetailsShow === 1">
      <el-pagination background layout="prev, pager, next" :total="pageTotalStree"
        :current-page="collectAuctionListData.page" :page-size="collectAuctionListData.pageSize"
        @current-change="handlePageChangeH">
      </el-pagination>
    </div>
    <!-- 拍卖会 结束-->
  </div>
</template>
<script>
  import {
    collectlist,
    collectAuctionList,
    collectAuctionHouseList,
    userCollect,
    collectAuction,
    collectAuctionHouse,
    collectItemlist,
    emindShooting
  } from '../../../api/index'
  export default {
    name: 'recommendedIteright',
    data() {
      return {
        userCollectData: {
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10,
          auctionStatus: 1
        },
        pageTotal: 0,
        pageTotalTwo: 0,
        pageTotalStree: 0,
        getAccountDetailsShow: 0,
        userCollectDataList: [],
        collectAuctionListData: {
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10,
          auctionStatus: 1
        },
        collectAucetionListData: [],
        getAccountDetailsIndex: 0,
        getAccountDetailsIndexColor: 3,
        collectAuctionHouseListData: {
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10
        },
        collectAuctionHouseListDataL: [],
        collectItemlistData: {
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: 0,
          page: 1,
          pageSize: 4
        },
        pageList: [],
        pageListShow: true,
        pageListId: '',
        listPD: -1,
        collectItemlistDataPage: 1,
        collectItemlistDataPageSize: 4
      }
    },
    created() {
      this.getAccountDetailsIndex = Number(sessionStorage.getItem('getAccountDetailsIndex')) || 0
      this.getAccountDetailsShow = Number(sessionStorage.getItem('getAccountDetailsIndex')) || 0
      this.getAccountDetailsIndex = 0
      this.getAccountDetailsShow = 0
      this.userCollect()
      // this.collectItemlist()
      this.getAccountDetailsIndexShow()
    },
    methods: {
      getAccountDetails(index) {
        this.getAccountDetailsShow = index
        this.getAccountDetailsIndex = index
        this.getAccountDetailsIndexColor = 3
        this.userCollectData.auctionStatus = 1
        this.collectAuctionListData.auctionStatus = 1
        this.userCollectData.page = 1
        this.collectAuctionHouseListData.page = 1
        this.collectAuctionListData.page = 1
        sessionStorage.setItem('getAccountDetailsIndex', index)
        if (index === 0) {
          this.userCollect()
        } else if (index === 1) {
          this.collectAuctionList()
        } else if (index === 2) {
          this.collectAuctionHouseList()
        }
      },
      getAccountDetailsIndexShow() {
        this.collectAuctionListData.page = 1
        this.collectAuctionHouseListData.page = 1
        this.userCollectData.page = 1
        if (Number(sessionStorage.getItem('getAccountDetailsIndex')) === 0) {
          this.userCollect()
        } else if (Number(sessionStorage.getItem('getAccountDetailsIndex')) === 1) {
          this.collectAuctionList()
        } else if (Number(sessionStorage.getItem('getAccountDetailsIndex')) === 2) {
          this.collectAuctionHouseList()
        }
      },
      // 拍品
      async userCollect() {
        const res = await collectlist(this.userCollectData)
        if (res.code === 0) {
          this.userCollectDataList = res.data.list
          this.pageTotal = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      auctionPage(id, index) {
        this.collectItemlistData.auctionId = id || 0
        this.pageListId = id
        this.listPD = index
        this.collectItemlist()
        this.userCollect()
      },
      // auctionPageTpa (id, index) {
      //   this.collectItemlistData.auctionId = id
      //   this.collectItemlist()
      //   this.userCollect()
      // },
      // 拍品分页
      handlePageChange(page) {
        this.collectItemlistData.page = page || 1
      },
      // handlePageChangeTpa (page) {
      //   this.collectItemlistDataPage = page
      //   // this.collectItemlistData.page = page
      // },
      // 拍卖行跳转
      collectAuctionHouseListGo(id) {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      },
      async collectItemlist() {
        const res = await collectItemlist(this.collectItemlistData)
        if (res.code === 0) {
          this.pageList = res.data.list
          this.pageListId = res.data.list[0].auctionId
          this.pageListShow = false
        } else {
          this.$message.error(res.msg)
        }
      },
      // 拍卖会分页
      handlePageChangeH(page) {
        this.collectAuctionListData.page = page
        this.collectAuctionList()
      },
      // 拍卖行分页
      handlePageChangeX(page) {
        this.collectAuctionHouseListData.page = page
        this.collectAuctionHouseList()
      },
      // 拍品拍卖会分页
      handlePageChangeP(page) {
        this.userCollectData.page = page
        // this.collectItemlistData.page = 1
        this.collectItemlistData.auctionId = 0
        this.auctionPage()
        // this.collectItemlist()
        // this.handlePageChange()
        // this.userCollect()
        // this.userCollect()
      },
      // 取消收藏
      async qxCollot(id) {
        const res = await userCollect({
          userid: Number(localStorage.getItem('accessId')),
          itemid: id,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success('取消成功~')
          this.userCollect()
          this.collectItemlist()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 拍卖会
      async collectAuctionList() {
        const res = await collectAuctionList(this.collectAuctionListData)
        if (res.code === 0) {
          this.pageTotalStree = res.data.count
          this.collectAucetionListData = res.data.list
          this.list = this.collectAucetionListData
          this.setTimeCount()
          this.setTimeCountTwo()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 即将开拍筛选
      sifting(index) {
        this.getAccountDetailsIndexColor = index
        this.collectAuctionListData.page = 1
        this.collectAuctionHouseListData.page = 1
        this.userCollectData.page = 1
        if (index === 4) {
          if (this.getAccountDetailsIndex === 1) {
            this.collectAuctionListData.auctionStatus = 0
            this.collectAuctionList()
          } else if (this.getAccountDetailsIndex === 0) {
            this.userCollectData.auctionStatus = 0
            this.userCollect()
          }
        } else if (index === 3) {
          if (this.getAccountDetailsIndex === 1) {
            this.collectAuctionListData.auctionStatus = 1
            this.userCollectData.page = 1
            this.collectAuctionListData.page = 1
            this.collectAuctionList()
          } else if (this.getAccountDetailsIndex === 0) {
            this.userCollectData.auctionStatus = 1
            this.userCollectData.page = 1
            this.collectAuctionListData.page = 1
            this.userCollect()
          }
        }
      },
      // 拍卖行列表
      async collectAuctionHouseList() {
        const res = await collectAuctionHouseList(this.collectAuctionHouseListData)
        if (res.code === 0) {
          this.collectAuctionHouseListDataL = res.data.list
          this.pageTotalTwo = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      // 拍品详情跳转
      detailsGo(id) {
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id
          }
        })
      },
      // 取消拍卖会
      async biddingQx(id) {
        const res = await collectAuction({
          userid: Number(localStorage.getItem('accessId')),
          auctionId: id,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success('取消成功')
          this.collectAuctionList()
        } else {
          this.$methods.error(res.msg)
        }
      },
      // 取消拍卖行
      async qxAuctionName(id) {
        const res = await collectAuctionHouse({
          userid: Number(localStorage.getItem('accessId')),
          auctionHouseId: id,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success('取消成功')
          this.collectAuctionHouseList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 开拍提醒
      async remind(id) {
        const res = await emindShooting({
          userId: Number(localStorage.getItem('accessId')),
          auctionId: id,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success(res.data.status?'系统将在拍卖会开始前1小时提醒您及时参与竞拍':'取消成功')
          this.collectAuctionList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖会
      auctiondetails(id) {
        sessionStorage.setItem('informationShow', 1)
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: id
          }
        })
      },
      // 跳转拍卖行详情
      auctionhousedetails(id) {
        sessionStorage.setItem('informationShow', 1)

        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      },
      // 跳转拍卖行
      // auctionhousedetails (id) {
      //   this.$router.push({
      //     path: '/auctionhousedetails',
      //     name: 'Auctionhousedetails',
      //     query: {
      //       auctionhousedetailsid: id
      //     }
      //   })
      // },
      setTimeCount() {
        const Y = new Date().getFullYear()
        const M = new Date().getMonth() + 1 - 0 >= 10 ? Number(new Date().getMonth()) + 1 : '0' + (Number(new Date()
          .getMonth()) + 1)
        const D = new Date().getDate() >= 10 ? new Date().getDate() : '0' + new Date().getDate()
        const h = new Date().getHours() >= 10 ? new Date().getHours() : '0' + new Date().getHours()
        const m = new Date().getMinutes() >= 10 ? new Date().getMinutes() : '0' + new Date().getMinutes()
        const s = new Date().getSeconds() >= 10 ? new Date().getSeconds() : '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        if (this.list !== undefined) {
          setInterval(() => {
            for (let j = 0; j < this.list.length; j++) {
              const now = Math.round(new Date().getTime())
              this.list[j].endTime = this.list[j].endTime.replace(/-/g, "/")
              let tmpProducts = []
              tmpProducts = this.list
              if (this.list[j].endTime !== null && date2 <= this.list[j].endTime) {
                this.$set(this.list[j], 'overtime', Date.parse(this.list[j].endTime) - now)
                this.$set(this.list[j], 'overD', Math.floor(Math.floor((Date.parse(tmpProducts[j].endTime) - now) /
                  1000 / 60 / 60 / 24)))
                this.$set(this.list[j], 'overH', Math.floor((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60 /
                  60 % 24))
                this.$set(this.list[j], 'overM', Math.floor((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60 %
                  60))
                this.$set(this.list[j], 'overS', Math.floor((Date.parse(tmpProducts[j].endTime) - now) / 1000 % 60))
              }
            }
          }, 1000)
        }
      },
      setTimeCountTwo() {
        const Y = new Date().getFullYear()
        const M = new Date().getMonth() + 1 - 0 >= 10 ? Number(new Date().getMonth()) + 1 : '0' + (Number(new Date()
          .getMonth()) + 1)
        const D = new Date().getDate() >= 10 ? new Date().getDate() : '0' + new Date().getDate()
        const h = new Date().getHours() >= 10 ? new Date().getHours() : '0' + new Date().getHours()
        const m = new Date().getMinutes() >= 10 ? new Date().getMinutes() : '0' + new Date().getMinutes()
        const s = new Date().getSeconds() >= 10 ? new Date().getSeconds() : '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        setInterval(() => {
          for (let j = 0; j < this.list.length; j++) {
            const now = Math.round(new Date().getTime())
            this.list[j].startTime = this.list[j].startTime.replace(/-/g, "/")
            let tmpProducts = []
            tmpProducts = this.list
            if (this.list[j].startTime !== null && date2 <= this.list[j].startTime) {
              this.$set(this.list[j], 'overtime1', Date.parse(this.list[j].startTime) - now)
              this.$set(this.list[j], 'overD1', Math.floor(Math.floor((Date.parse(tmpProducts[j].startTime) - now) /
                1000 / 60 / 60 / 24)))
              this.$set(this.list[j], 'overH1', Math.floor((Date.parse(tmpProducts[j].startTime) - now) / 1000 /
                60 / 60 % 24))
              this.$set(this.list[j], 'overM1', Math.floor((Date.parse(tmpProducts[j].startTime) - now) / 1000 /
                60 % 60))
              this.$set(this.list[j], 'overS1', Math.floor((Date.parse(tmpProducts[j].startTime) - now) / 1000 %
                60))
            }
          }
        }, 1000)
      },
      // 跳转拍卖会
      titleAuction(id) {
        sessionStorage.setItem('informationShow', 1)
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: id
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-recommendedIteright {
    width: 1400px;

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    .pagination {
      margin-top: 20px;
      text-align: right;
    }

    .acount-view-middle {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;
      display: flex;
      padding: 20px 0;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;

      .acount-view-middle-left-10 {
        display: flex;

        .acount-view-middle-1 {
          width: 200px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 20px;

          // padding-top: 10px;
          a {
            color: #666;

            &:hover {
              color: #cda156;
              border-bottom: 2px solid #cda156;
            }
          }

          .active {
            color: #cda156;
            border-bottom: 2px solid #cda156;
          }
        }

        .br {
          border-right: 1px solid #bfc3c4;
        }
      }

      .acount-view-middle-right-10 {
        font-size: 18px;

        .line-ss {
          padding: 0 5px;
        }

        .active {
          color: #cda156 !important;
          // border-bottom: 2px solid #cda156;
        }

        .right-cad156 {
          color: #333;

          &:hover {
            color: #cda156;
          }
        }
      }
    }

    .acount-view-bottom {
      padding-left: 20px;
      padding-top: 20px;
      background: #fff;
      margin-top: 20px;
      padding-bottom: 20px;
      padding-right: 30px;

      .recommendedIteright-text-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .recommendedIteright-text-left {
          p {
            margin-bottom: 0;
          }

          .p1 {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            padding-bottom: 10px;
            display: block;

            &:hover {
              text-decoration: underline;
            }
          }

          .p3:hover {
            text-decoration: underline;
          }

          .p2 {
            font-size: 20px;
            font-weight: 400;
            color: #666;

            .line-text-1 {
              padding: 0 10px;
            }
          }
        }

        .recommendedIteright-text-right {
          width: 200px;
          height: 44px;
          text-align: center;
          line-height: 44px;
          color: #6e5121;
          font-weight: 600;
          //opacity: 0.69;
          border-radius: 8px;
          background: #cda156;
          background: rgba(205, 161, 86, 0.69);
          font-size: 18px;
        }

        .recommendedIteright-text-right-222 {
          width: 200px;
          height: 44px;
          text-align: center;
          line-height: 44px;
          color: #333;
          font-weight: 600;
          // opacity: 0.69;
          border-radius: 8px;
          //background: #fff;
          background: rgba(255, 255, 255, .69);
          font-size: 18px;
        }
      }

      .recommendedIteright-text-bottom {
        .recommendedIteright-text-bottom-k-2 {
          width: 307px;
          // height: 461px;
          margin-top: 20px;
          box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.05);
          padding-bottom: 30px;
          margin-right: 30px;
          display: inline-block;
          position: relative;

          &:hover {
            box-shadow: 0px 1px 16px 0px rgba(29, 29, 29, 0.05);
          }

          .top-img-5 {
            width: 300px;
            position: relative;

            .status-item {
              width: 80px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 18px;
              font-weight: 600;
              background: #d10000;
              color: #fff;
              position: absolute;
              left: 0;
              bottom: 0;
            }

            i {
              position: absolute;
              top: 10px;
              right: 17px;
              font-size: 30px;
              color: rgb(234, 49, 74);
            }

            .col-md-3 {
              margin: 0;
              border: none;
              height: none;
              display: flex;

              .thumb-container {
                width: 100%;
                // height: 405px;
                position: relative;
                padding-bottom: 100%;
                margin: 0;
                border: none;

                &:hover {
                  box-shadow: none;
                }

                .thumb {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  margin: 0;
                  border-top: none;
                  border-bottom: none;
                  border-left: none;

                  // top: 32%;
                  &:hover {
                    box-shadow: none;
                  }

                  a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;

                    img {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;
                    }
                  }
                }
              }
            }
          }

          .top-img-5-bottom {
            padding-left: 20px;
            display: flex;
            flex-direction: column;

            .lot-h {
              padding-top: 20px;
              padding-bottom: 10px;
              font-size: 16px;
              color: #333;
              width: 280px;
              // height: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &:hover {
                text-decoration: underline;
              }
            }

            .ppgj-price {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }

            .qpjg-price {
              font-size: 16px;
              font-weight: 600;
              color: #333;
            }
          }
        }
      }
    }

    .auctionName-view {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .auctionName-view-for {
        width: 690px;
        height: 160px;
        background: #fff;
        padding: 10px 0 20px 20px;
        display: flex;
        align-items: center;
        // margin-right: 10px;
        margin-bottom: 10px;

        .auctionName-view-for-img {
          width: 150px;

          .col-md-3 {
            margin: 0;
            border: none;
            height: none;
            display: flex;

            .thumb-container {
              width: 100%;
              // height: 405px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              &:hover {
                box-shadow: none;
              }

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                // top: 32%;
                &:hover {
                  box-shadow: none;
                }

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }
        }

        .auctionName-view-for-text {
          margin-left: 25px;

          .auctionName-view-for-text-top {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            margin-bottom: 20px;
            display: inline-block;

            &:hover {
              text-decoration: underline;
            }
          }

          .auctionName-view-for-text-bottom {
            width: 80px;
            height: 24px;
            opacity: 0.7;
            border-radius: 4px;
            border: 1px solid #cda156;
            text-align: center;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            .bo-tx {
              color: #cda156;
              padding-left: 5px;
            }

            .ax-bo {
              color: #cda156;
            }
          }
        }
      }
    }

    .bidding-main {
      .bidding-main-right:hover {
        box-shadow: none;
      }

      .col-md-3:hover {
        box-shadow: none;
      }

      .bidding-main-for {
        background: #fff;
        display: flex;
        padding-left: 29px;
        margin-bottom: 20px;

        .forBackgroundImage {
          background-size: 80% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 452px;
          // z-index: -1;
          margin: 0;
          opacity: 1;
          // height: 100%;
        }

        .col-md-3 {
          width: 480px;
          margin: 0;
          border: none;
          height: none;

          .thumb-container {
            width: 100%;
            height: 317px;
            position: relative;
            // padding-bottom: 100%;
            margin: 0;
            border: none;

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }

        .bidding-main-right {
          width: 1320px;
          height: 100%;
          margin: 0;
          border: none;
          box-sizing: border-box;
          padding: 0px 30px 0 30px;
          display: flex;
          justify-content: space-between;

          .bidding-main-right-left {
            margin: 0;
            border: none;
            box-shadow: none;
            flex-direction: column;
            text-align: left;
            margin-top: 40px;

            // justify-content: center;
            p {
              // margin-top: 20px;
            }

            .yeelow {
              // color: #cda156 !important;
            }

            .bidding-collot {
              margin: 0;
              border: none;
              padding-bottom: 20px;
              align-items: center;
              display: flex;

              &:hover {
                box-shadow: none;
              }

              a:nth-child(1) {
                // margin-top: 40px;
                // width: 900px;
                font-size: 24px;
                font-weight: 500;
                color: #333;
                width: 300px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;

                &:hover {
                  text-decoration: underline;
                }
              }

              .right-collot {
                width: 80px;
                height: 24px;
                border: 1px solid #cda156;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: block;
                line-height: 21px;
                text-align: center;
                color: #cda156;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot {
                  font-size: 14px;
                  color: #cda156;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }

              .right-collot-border-ss {
                width: 80px;
                height: 24px;
                border: 1px solid #999;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: block;
                line-height: 21px;
                text-align: center;
                color: #999;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 0;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot {
                  font-size: 14px;
                  color: #999;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

            a:nth-child(2) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
              padding-bottom: 20px;

              &:hover {
                text-decoration: underline;
              }
            }

            p:nth-child(3) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              span:nth-child(2) {
                padding: 0 10px;
              }
            }

            p:nth-child(4) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
            }

            p:nth-child(5) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              img {
                width: 14px;
                height: 20px;
                margin-right: 10px;
                transform: translateY(-2px);
              }
            }
          }

          .right-browse {
            margin: 0;
            border: none;
            flex-direction: column;
            display: flex;
            align-items: center;

            &:hover {
              box-shadow: none;
            }

            .bidding-main-right-right {
              display: flex;
              margin: 0;
              border: none;
              box-shadow: none;
              width: 280px;
              height: 44px;
              background: #cda156;
              border-radius: 8px;
              opacity: 0.7;
              color: #6e5121;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              margin-top: 40px;
            }

            .bidding-main-right-right-col {
              background: #fff;
              color: #333;
            }

            .start-bidding {
              padding-top: 20px;
              font-size: 18px;
              color: #cda156;
            }

            .cut-off {
              color: #666;
              font-size: 20px;
              padding-top: 20px;
            }

            .pd-off {
              padding-top: 4px;
              text-align: center;
            }
          }
        }
      }
    }
  }
</style>
