<template>
  <div class="receivinggoods-view">
    <div class="receivinggoods-view-top">
      <span class="receivinggoods-view-top-1">收货地址</span>
      <span class="receivinggoods-view-top-2">（最多可保存10条收货地址）</span>
    </div>
    <div class="receivinggoods-view-bottom">
      <a href="javascript:;" class="add-location" @click="addReceivinggoods">新增收货地址</a>
      <div class="receivinggoods-view-bottom-list" v-for="(item,index) in addressListDl " :key="index">
        <div class="receivinggoods-view-bottom-list-left">
          <div class="receivinggoods-view-bottom-list-1">
            <span>收货人：</span>
            <span>{{item.realName}}</span>
            <span class="defult-location" v-if="item.isDefault">默认地址</span>
          </div>
          <div class="receivinggoods-view-bottom-list-1">
            <span>联系方式：</span>
            <span>{{item.mobile}}</span>
          </div>
          <div class="receivinggoods-view-bottom-list-1">
            <span>收货地址：</span>
            <span>{{item.code + item.addressDetails}}</span>
          </div>
          <div class="receivinggoods-view-bottom-list-1">
            <span>邮编地址：</span>
            <span>{{item.zipCode}}</span>
          </div>
        </div>
        <div class="receivinggoods-view-bottom-list-right">
          <a href="javascript:;" class="flex1" @click="defaultAddress(item.id,item.isDefault)">设置默认</a>
          <a href="javascript:;" class="iconfont icon-bianji flex1" @click="editEceivinggoods(item.id)"></a>
          <a href="javascript:;" class="iconfont icon-shanchu1 flex1 f1-size" @click="delAddress(item.id)"></a>
        </div>
      </div>
    </div>
    <!-- 新增 -->
    <Addreceivinggoods ref="addreceivinggoods" @addReceivinggoodsSave='addReceivinggoodsSave' />
    <!--编辑 -->
    <Editeceivinggoods ref="editeceivinggoods" :editEceivinggoodsId='editEceivinggoodsId'
      @EditReceivinggoodsSave='EditReceivinggoodsSave' />
  </div>
</template>
<script>
import {
  addressList,
  delAddress,
  defaultAddress
} from '../../../api/index'
import Addreceivinggoods from './Addreceivinggoods.vue'
import Editeceivinggoods from './Editeceivinggoods.vue'
export default {
  data () {
    return {
      addressListData: {
        userid: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        page: 1,
        pageSize: 10
      },
      addressListDl: [],
      editEceivinggoodsId: 0
    }
  },
  components: {
    Addreceivinggoods,
    Editeceivinggoods
  },
  created () {
    this.addressList()
  },
  methods: {
    async addressList () {
      const res = await addressList(this.addressListData)
      if (res.code === 0) {
        this.addressListDl = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    // 新增收货地址
    addReceivinggoods () {
      this.$nextTick(() => {
        this.$refs.addreceivinggoods.open()
      })
    },
    // 新增后刷新
    addReceivinggoodsSave () {
      this.addressList()
    },
    // 编辑
    editEceivinggoods (id) {
      this.editEceivinggoodsId = id
      this.$nextTick(() => {
        this.$refs.editeceivinggoods.open()
      })
    },
    EditReceivinggoodsSave () {
      this.addressList()
    },
    // 设置默认地址
    async defaultAddress (id) {
      const res = await defaultAddress({
        id: id,
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })
      if (res.code === 0) {
        this.$message.success('设置成功')
        this.addressList()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 删除
    async delAddress (id) {
      const res = await delAddress({
        userid: Number(sessionStorage.getItem('accessId')),
        id: id,
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })
      if (res.code === 0) {
        this.$message.success('删除成功')
        this.addressList()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .receivinggoods-view {
    width: 1400px;

    /deep/ .el-dialog__wrapper {
      z-index: 100 !important;
    }

    .receivinggoods-view-top {
      width: 100%;
      height: 60px;
      background: #fff;
      padding-left: 30px;
      // margin-top: 20px;
      line-height: 60px;

      .receivinggoods-view-top-1 {
        font-size: 20px;
        font-weight: 600;
        color: #333;
      }

      .receivinggoods-view-top-2 {
        font-size: 18px;
        font-weight: 400;
        color: #333;
      }
    }

    .receivinggoods-view-bottom {
      width: 100%;
      background: #fff;
      padding-left: 30px;
      padding-top: 20px;
      margin-top: 10px;
      padding-bottom: 20px;
      padding-right: 50px;

      .add-location {
        display: inline-block;
        width: 200px;
        border: 1px solid #cda156;
        opacity: 0.69;
        border-radius: 8px;
        background: #ffffff;
        height: 44px;
        color: #cda156;
        font-size: 18px;
        text-align: center;
        line-height: 44px;
        margin-bottom: 20px;
      }

      .receivinggoods-view-bottom-list {
        width: 1280px;
        height: 204px;
        padding: 30px 0 32px 30px;
        border: 1px solid #dedede;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        padding-right: 50px;

        .receivinggoods-view-bottom-list-left {
          display: flex;
          flex: 1;
          flex-direction: column;

          .receivinggoods-view-bottom-list-1 {
            flex: 1;
            font-size: 20px;
            font-weight: 400;
            color: #333333;
            display: flex;
            align-items: center;

            .defult-location {
              display: inline-block;
              width: 79px;
              height: 24px;
              border-radius: 3px;
              background: #cda156;
              color: #fff;
              text-align: center;
              line-height: 24px;
              margin-left: 10px;
              font-size: 14px;
            }
          }
        }

        .receivinggoods-view-bottom-list-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 200px;

          .flex1 {
            //flex: 1;
            font-weight: 400;
            font-size: 20px;
            color: #cda156;
            text-align: center;
          }

          .f1-size {
            font-size: 27px !important;

          }
        }
      }
    }
  }
</style>
