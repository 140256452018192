<template>
  <div>
    <div class="reminder-top">
      <span :class="tabIndex==0?'tab select':'tab'" @click="tabIndex=0">拍品提醒</span>
      <span class="line"></span>
      <span :class="tabIndex==1?'tab select':'tab'" @click="tabIndex=1">拍卖会提醒</span>
    </div>
    <ReminderGoods v-show="tabIndex==0" />
    <ReminderAuction v-show="tabIndex==1" />

  </div>
</template>

<script>
  import ReminderAuction from './ReminderAuction.vue'
  import ReminderGoods from './ReminderGoods.vue'
  export default {
    name: 'Reminder',
    components: {
      ReminderAuction,
      ReminderGoods
    },
    props: {

    },
    data() {
      return {
        tabIndex: 0
      }
    },
    created() {

    },
    methods: {

    }
  }
</script>

<style lang="less" scoped>
  .reminder-top {
    width: 100%;
    background: #fff;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    height: 60px;

    >.tab {
      width: 240px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: 400;
      color: #666666;
      position: relative;
      cursor: pointer;
    }
    >.select{
      color: #CDA156 !important;
      font-weight: 600 !important;
      &::after{
        display: block;
        content: '';
        width: 100px;
        height: 4px;
        background: #CDA156;
        position: absolute;
        bottom: 0;
      }
    }
    >.line{
      width: 1px;
      height: 20px;
      background: #BFC3C4;
    }
  }
</style>
