<template>
  <div class="auctionrecords-view">
    <div class="acount-view-middle">
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" @click="getAccountDetails(0)"
          :class="[getAccountDetailsShow === 0 ? 'active' : '']">全部</a>
      </div>
      <div class="acount-view-middle-1 br ">
        <a href="javascript:;" :class="[getAccountDetailsShow === 1 ? 'active' : '']"
          @click="getAccountDetails(1)">竞价中</a>
      </div>
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow === 5 ? 'active' : '']"
          @click="getAccountDetails(5)">待确认</a>
      </div>
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow === 3 ? 'active' : '']"
          @click="getAccountDetails(3)">已竞得</a>
      </div>
      <div class="acount-view-middle-1">
        <a href="javascript:;" :class="[getAccountDetailsShow === 2 ? 'active' : '']"
          @click="getAccountDetails(2)">未竞得</a>
      </div>
    </div>
    <!--   <div class="acount-view-bottom-middle">
      <div class="acount-view-bottom-middle-nei">
        <span>拍品详情</span>
        <span>我的最高出价</span>
        <span>当前出价</span>
        <span>状态</span>
        <span>操作</span>
      </div>
    </div> -->
    <div class="acount-view-bottom-middle-di-text" v-for="(itemp, index) in list" :key="index">
      <div class="acount-view-bottom-middle-di-text-top">
        <div class="left-c-z">
          <router-link target="_blank" class="wight" :to="{
              path: '/auctiondetails',
              query: { auctiondetailsId: itemp.auctionId }
            }">
            {{ itemp.auctionName }}
          </router-link>
          <!-- <a class="wight" href="javascript:;" @click="auctionId(item.auctionId)">{{item.auctionName}}</a> -->
          <router-link target="_blank" class="wight-2" :to="{
              path: '/auctionhousedetails',
              query: { auctionhousedetailsid: itemp.auctionHouseId }
            }">
            拍卖行：{{ itemp.auctionHouseName }}
          </router-link>
          <div class="startTime">开拍时间：{{itemp.startTime.replace(/\//g, '-')}}</div>
          <!-- <a class="wight-2" href="javascript:;"
            @click="auctionHouseId(item.auctionHouseId)">{{item.auctionHouseName}}</a> -->
        </div>
        <div class="right-c-z">
          <div v-if="itemp.attrAuctionWayId === 8" class="zhuanchang">代拍专场</div>
          <div v-if="itemp.attrAuctionWayId === 7&&itemp.auctionStatus != 2" class="zhuanchang">直播专场</div>
          <span class="cut-off pd-off" v-if="
              itemp.attrAuctionWayId === 8 &&
                itemp.auctionStatus === 1 &&
                itemp.overD !== undefined &&
                itemp.deferment === false
            ">
            <i style="font-style:normal;">委托倒计时:</i>
            <i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overD
            }}</i>天<i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overH
            }}</i>时<i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overM
            }}</i>分<i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overS
            }}</i>秒
          </span>
          <span class="cut-off pd-off" v-else-if="
                itemp.attrAuctionWayId === 8 &&
                  (itemp.list?itemp.list[0].status==2||itemp.list[0].status==3||itemp.list[0].status==5:itemp.overD1 === undefined)&&
                  itemp.deferment === false
              ">拍卖已结束</span><!-- 优先显示拍卖已结束 -->
          <span class="cut-off pd-off" v-else-if="
              itemp.attrAuctionWayId === 8 &&
                itemp.auctionStatus === 1 &&
                itemp.overD === undefined &&
                itemp.deferment === false
            ">线上委托已截止</span>

          <span class="cut-off pd-off" v-if="
              itemp.attrAuctionWayId === 7 &&
                itemp.auctionStatus == 1 &&
                itemp.overD1 !== undefined &&
                itemp.deferment === false
            ">
            <i style="font-style:normal;">直播倒计时:</i>
            <i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overD1
            }}</i>天<i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overH1
            }}</i>时<i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overM1
            }}</i>分<i style="color:rgb(197, 80, 86);font-style:normal;">{{
              itemp.overS1
            }}</i>秒
          </span>
          <!--          <span class="cut-off pd-off" v-else-if="item.overD === undefined && item.attrAuctionWayId === 8">委托已结束</span>
          <span class="cut-off pd-off" v-else-if="item.overD === undefined && item.attrAuctionWayId === 7">直播已结束</span> -->

          <!--          <span class="cut-off pd-off" v-else-if="item.overD === undefined && item.attrAuctionWayId === 8">委托已结束</span>
          <span class="cut-off pd-off" v-else-if="item.attrAuctionWayId === 7&&item.auctionStatus==2">直播中</span>
          <span class="cut-off pd-off" v-else-if="item.attrAuctionWayId === 7&&item.auctionStatus==4">直播已结束</span> -->

          <!-- <span class="cut-off pd-off" v-else-if="item.auctionStatus==1">预约出价中</span> -->
          <span class="cut-off pd-off pd-red" v-if="
              itemp.attrAuctionWayId === 7 &&
                itemp.auctionStatus == 2 &&
                itemp.deferment === false
            ">拍卖直播中</span>
          <span class="cut-off pd-off" v-if="
              itemp.attrAuctionWayId === 7 &&
                itemp.auctionStatus == 3 &&
                itemp.deferment === false
            ">拍卖顺延</span>
          <span class="cut-off pd-off" v-if="
              itemp.attrAuctionWayId === 7 &&
                itemp.auctionStatus == 4 &&
                itemp.deferment === false
            ">拍卖已结束</span>
          <span class="cut-off pd-off" v-if="itemp.deferment === true">延期待定</span>
        </div>
      </div>
      <div class="acount-view-bottom-middle-di-text-bottom" v-for="(item, index) in itemp.list" :key="index">
        <div class="auction-img">
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{
                    path: '/productdetails',
                    query: { productdetailsId: item.itemId }
                  }">
                  <!-- <a href="javascript:;" @click="product(item.itemId)"> -->
                  <img :src="item.image" alt="" />
                  <!-- </a> -->
                </router-link>
              </div>
            </div>
          </div>
          <div class="auction-bo-im">
            <router-link target="_blank" :to="{
                path: '/productdetails',
                query: { productdetailsId: item.itemId }
              }">
              Lot {{ item.lot }}:{{ item.title }}
            </router-link>
            <!-- <a href="javascript:;" @click="product(item.itemId)">Lot {{item.lot}}:{{item.title}}</a> -->
          </div>
        </div>
        <div class="auction-lot-yuan">
          <span class="auction-lot-title">我的最高出价</span>
          <span>{{ item.highestBid }}{{ item.currency }}</span>
          <span class="statusOut"
            v-if="itemp.attrAuctionWayId == 7&&item.status==1&&formatDel(item.highestBid)<formatDel(item.currentBid)">出局</span>
        </div>
        <div class="auction-lot-chujia">
          <!--          <template v-if="item.dropHammerPrice==''||item.dropHammerPrice=='0'||item.dropHammerPrice=='0.00'">
            <span class="auction-lot-title">当前出价</span>
            <span>{{ item.currentBid }}{{ item.currency }}</span>
          </template>
          <template v-else>
            <span class="auction-lot-title">落槌价</span>
            <span>{{ item.dropHammerPrice }}{{ item.currency }}</span>
          </template> -->

          <span class="auction-lot-title"
            v-if="itemp.attrAuctionWayId == 8&&item.itemStatus==4||item.itemStatus==5">{{item.itemStatus==4?'流拍':'撤拍'}}</span>

          <template
            v-else-if="item.dropHammerPrice!=''&&item.dropHammerPrice!='0'&&item.dropHammerPrice!='0.00'&&item.itemStatus!=4&&item.itemStatus!=5">
            <span class="auction-lot-title">落槌价</span>
            <span>{{ item.dropHammerPrice }}{{ item.currency }}</span>
          </template>
          <template v-else>
            <span class="auction-lot-title">当前出价</span>
            <span>{{ item.currentBid }}{{ item.currency }}</span>
          </template>
          <a href="javascript:;" @click="bidRecord(item.itemId,item.itemStatus,item.status,item.auctionWayId)">出价记录</a>
        </div>

        <div class="auction-lot-jingjia" v-if="item.auctionWayId === 8">
          <span class="auction-lot-title">状态</span>
          <span :class="[
              item.status == '1' ? 'cda156-1' : item.status == '2' ? 'c999' : ''
            ]">
            {{
             item.itemStatus==4
             ?'未竞得'
             :item.itemStatus==5
             ?'未竞得'
             :item.status == '1'
                ? '竞价中'
                : item.status == '2'
                ? '未竞得'
                : item.status == '3'
                ? '已竞得'
                : item.status == '5'
                ? '待确认'
                : ''
            }}
          </span>
        </div>

        <!--        <div class="auction-lot-jingjia" v-if="item.auctionWayId === 7">
          <span :class="[item.status=='3'?'cda156-1':'c999']">
            {{item.status=='1'?'待拍' : item.status=='2'?'竞拍中': item.status=='3'?'成交': item.status=='4'?'流拍':item.status=='5'?'撤拍':''}}
          </span>
        </div> -->
        <div class="auction-lot-jingjia" v-if="item.auctionWayId === 7">
          <span class="auction-lot-title">状态</span>
          <span class="cda156-1" v-if="item.itemStatus==1">待拍</span>
          <span v-else :class="[item.status == '2' ? 'c999':item.status == '3'?'':'cda156-1'  ]">
            {{
              item.itemStatus==4
              ?'流拍'
              :item.itemStatus==5
              ?'撤拍'
              :item.status == '1'
                ? '竞价中'
                : item.status == '2'
                ? '未竞得'
                : item.status == '3'
                ? '已竞得'
                : item.status == '4'
                ? '取消'
                : item.status == '5'
                ? '待确认'
                : ''
            }}
          </span>
        </div>
        <div class="auction-lot-caozuo" v-if="item.status === '1'">
          <router-link target="_blank" v-if="
              item.auctionWayId === 8 &&
                item.itemStatus === 1 &&
                Date.parse(itemp.endTime) > Date.parse(dataIf)
            " class="ti" :to="{
              path: '/productdetails',
              query: { productdetailsId: item.itemId }
            }">
            提高出价
          </router-link>
          <!-- <a href="javascript:;" v-else-if="item.auctionWayId === 8 && item.itemStatus !==1" class="che">提高出价</a> -->
          <router-link target="_blank" v-if="item.auctionWayId === 7 && item.itemStatus === 2" class="ti" :to="{
              path: '/buyerlive',
              query: {
                auctionId: item.auctionId,
                auctionHouseTitle: item.auctionHouseName,
                title: item.auctionName
              }
            }">
            提高出价
          </router-link>
          <router-link target="_blank" v-if="item.auctionWayId === 7 && item.itemStatus === 1" class="ti" :to="{
              path: '/productdetails',
              query: { productdetailsId: item.itemId }
            }">
            提高出价
          </router-link>
          <a href="javascript:;" v-else-if="
              item.auctionWayId === 7 &&
                item.itemStatus === 3 &&
                item.itemStatus === 4 &&
                item.itemStatus === 5
            " class="che">提高出价</a>
          <!-- <a href="javascript:;" class="ti" @click="handleHeight(item.itemId)">提高出价</a> -->
          <a href="javascript:;" v-if="
              item.auctionWayId === 8 &&
                item.itemStatus === 1 &&
                Date.parse(itemp.endTime) > Date.parse(dataIf)
            " class="che" @click="recanted(item.itemId)">撤销出价</a>
          <a href="javascript:;" v-if="item.auctionWayId === 7 && item.itemStatus === 1" class="che"
            @click="recanted(item.itemId)">撤销出价</a>
          <a href="javascript:;" v-else-if="
              item.auctionWayId === 7 &&
                item.itemStatus === 3 &&
                item.itemStatus === 4 &&
                item.itemStatus === 5
            " class="che">撤销出价</a>
        </div>
        <div class="auction-lot-caozuo" v-else-if="item.status === '3'&&!item.confirm">
          <router-link target="_blank" to="/personalcenter" class="che" @click.native="lookOrder(item.orderNo)"
            v-if="item.orderNo !== ''">查看订单</router-link>
        </div>
        <!-- <div class="auction-lot-caozuo" v-else>
          <span>无</span>
        </div> -->
      </div>
    </div>

    <Bidrecord ref="bidrecord" :bidRecordId="bidRecordId" :bidStatus="bidStatus" :bidWayId="bidWayId" />
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="pageTotal"
        :current-page="getAccountDetailsListData.page" :page-size="getAccountDetailsListData.pageSize"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    getAuctionRecord,
    cancelOffer
  } from '../../../api/index'
  import Bidrecord from './Bidrecord.vue'
  let countTimer = null
  export default {
    components: {
      Bidrecord
    },
    data() {
      return {
        getAccountDetailsListData: {
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          type: 0,
          page: 1,
          pageSize: 10
        },
        pageTotal: 0,
        list: [],
        iconlist: [],
        ticker: null,
        d: '',
        h: '',
        m: '',
        s: '',
        dialogVisible: false,
        bidRecordId: 0,
        bidStatus: '',
        getAccountDetailsShow: 0,
        dataIf: '',
        bidWayId: ''
      }
    },
    inject: ['reload'],
    created() {
      this.getAccountDetailsList()
      this.setTimeCount()
      this.times()
    },
    destroyed() {
      clearInterval(countTimer)
    },
    methods: {
      formatDel(num) { // 去除千分号
        const newval = num.toString().replace(/,/gi, '')
        return Number(newval)
      },
      handleHeight(id) {
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id
          }
        })
      },
      getAccountDetails(index) {
        this.getAccountDetailsListData.page = 1
        this.getAccountDetailsList(index)
        this.getAccountDetailsShow = index
      },
      async getAccountDetailsList(index) {
        this.getAccountDetailsListData.type = index || 0
        const res = await getAuctionRecord(this.getAccountDetailsListData)
        if (res.code === 0) {
          res.data.list?.map(item => {
            if (item.list) {
              const confirm = item.list.some(item2 => item2.status == 5 && item2.itemStatus != 4 && item2
                .itemStatus != 5)
              item.list.map(item => {
                item.confirm = confirm
              })
            }
          })
          this.list = res.data.list
          this.pageTotal = res.data.count
          // if (this.list !== null) {
          //   for (const i in this.list) {
          //     this.list[i].countDownTime = ''
          //     this.countDown(i)
          //   }
          // }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 查看订单
      lookOrder(id) {
        localStorage.setItem('informationShow', 6)
        localStorage.setItem('orderNo', id)
        // this.$router.push({
        //   path: '/personalcenter',
        //   name: 'Personalcenter'
        // })
        // this.reload()
      },
      // 分页
      handlePageChange(page) {
        this.getAccountDetailsListData.page = page

        if (this.getAccountDetailsShow === 5) {
          this.getAccountDetailsList(5)
        } else if (this.getAccountDetailsShow === 3) {
          this.getAccountDetailsList(3)
        } else if (this.getAccountDetailsShow === 2) {
          this.getAccountDetailsList(2)
        } else if (this.getAccountDetailsShow === 1) {
          this.getAccountDetailsList(1)
        } else if (this.getAccountDetailsShow === 0) {
          this.getAccountDetailsList(0)
        }
      },
      // 出价记录
      bidRecord(id, itemStatus, status, wayId) {
        this.bidRecordId = id
        if (status == 2 || status == 3) {
          this.bidStatus = status
        }
        if (itemStatus == 4 || itemStatus == 5) {
          this.bidStatus = itemStatus
        }
        this.bidWayId = wayId
        this.$nextTick(() => {
          this.$refs.bidrecord.open()
        })
      },
      // 撤销出价
      async recanted(id) {
        const res = await cancelOffer({
          id: id
        })
        if (res.resultCode === 0) {
          this.$message.success('撤销成功')
          this.getAccountDetailsList()
        } else if (res.resultCode === 503) {
          this.$message.error(res.resultMsg)
        } else {
          this.$message.error(res.resultMsg)
        }
      },
      // 跳转拍品
      changProduct(id) {
        sessionStorage.setItem('informationShow', 5)
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id
          }
        })
      },
      // 时间转换
      times() {
        var date = new Date() // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '/'
        var M =
          (date.getMonth() + 1 < 10 ?
            '0' + (date.getMonth() + 1) :
            date.getMonth() + 1) + '/'
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        var h =
          (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
        var m =
          (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
          ':'
        var s =
          date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        this.dataIf = Y + M + D + ' ' + h + m + s // 时分秒可以根据自己的需求加上
      },
      // 倒计时
      countDownFun(time) {
        // console.log(time)
        const startTime = new Date() // 当前时间
        const end = new Date(time) // 结束时间
        // console.log(end)
        const result = parseInt((end - startTime) / 1000) // 计算出豪秒
        const d = parseInt(result / (24 * 60 * 60)) // 用总共的秒数除以1天的秒数
        let h = parseInt((result / (60 * 60)) % 24) // 精确小时，用去余
        const m = parseInt((result / 60) % 60) // 剩余分钟就是用1小时等于60分钟进行趣余
        let s = parseInt(result % 60)
        // 当倒计时结束时，改变内容
        if (result <= 0) {
          return '已结束'
        }
        if (h < 10) {
          h = '0' + h
        }
        if (s < 10) {
          s = '0' + s
        }
        if (h === 0 && m === 0) {
          return s + '秒'
        } else if (h === 0) {
          return m + '分' + s + '秒'
        } else if (d === 0) {
          return h + '时' + m + '分' + s + '秒'
        } else {
          return d + '天' + h + '时' + m + '分' + s + '秒'
        }
      },

      // 定时器
      // 页面多个倒计时 归零时清除
      countDown(i) {
        const that = this
        const item = that.list[i]
        that.list[i].countDownFn = setInterval(() => {
          //  console.log(that.countDownFun(item.endTime))
          if (that.countDownFun(item.countDownTime) === '已结束') {
            clearInterval(that.list[i].countDownFn) // 清除定时器
          } else {
            item.countDownTime = that.countDownFun(item.endTime)
            that.$set(
              that.list,
              item.countDownTime,
              that.countDownFun(item.endTime)
            )
          }
        }, 1000)
      },
      setTimeCount() {
        const Y = new Date().getFullYear()
        const M =
          new Date().getMonth() + 1 - 0 >= 10 ?
          Number(new Date().getMonth()) + 1 :
          '0' + (Number(new Date().getMonth()) + 1)
        const D =
          new Date().getDate() >= 10 ?
          new Date().getDate() :
          '0' + new Date().getDate()
        const h =
          new Date().getHours() >= 10 ?
          new Date().getHours() :
          '0' + new Date().getHours()
        const m =
          new Date().getMinutes() >= 10 ?
          new Date().getMinutes() :
          '0' + new Date().getMinutes()
        const s =
          new Date().getSeconds() >= 10 ?
          new Date().getSeconds() :
          '0' + new Date().getSeconds()
        const date2 = Y + '/' + M + '/' + D + ' ' + h + ':' + m + ':' + s
        if (this.list) {
          countTimer = setInterval(() => {
            if (!this.list) return
            for (let j = 0; j < this.list.length; j++) {
              const now = Math.round(new Date().getTime())
              this.list[j].startTime = this.list[j].startTime.replace(/-/g, "/")
              this.list[j].endTime = this.list[j].endTime.replace(/-/g, "/")
              let tmpProducts = []

              tmpProducts = this.list

              // 直播倒计时时间
              if (
                this.list[j].startTime !== null &&
                date2 <= this.list[j].startTime
              ) {
                this.$set(
                  this.list[j],
                  'overtime1',
                  Date.parse(this.list[j].startTime) - now
                )
                this.$set(
                  this.list[j],
                  'overD1',
                  Math.floor(
                    Math.floor(
                      (Date.parse(tmpProducts[j].startTime) - now) /
                      1000 /
                      60 /
                      60 /
                      24
                    )
                  )
                )
                this.$set(
                  this.list[j],
                  'overH1',
                  Math.floor(
                    ((Date.parse(tmpProducts[j].startTime) - now) /
                      1000 /
                      60 /
                      60) %
                    24
                  )
                )
                this.$set(
                  this.list[j],
                  'overM1',
                  Math.floor(
                    ((Date.parse(tmpProducts[j].startTime) - now) / 1000 / 60) %
                    60
                  )
                )
                this.$set(
                  this.list[j],
                  'overS1',
                  Math.floor(
                    ((Date.parse(tmpProducts[j].startTime) - now) / 1000) % 60
                  )
                )
              }
              // 委托倒计时
              if (
                this.list[j].endTime !== null &&
                date2 <= this.list[j].endTime
              ) {
                this.$set(
                  this.list[j],
                  'overtime',
                  Date.parse(this.list[j].endTime) - now
                )
                this.$set(
                  this.list[j],
                  'overD',
                  Math.floor(
                    Math.floor(
                      (Date.parse(tmpProducts[j].endTime) - now) /
                      1000 /
                      60 /
                      60 /
                      24
                    )
                  )
                )
                this.$set(
                  this.list[j],
                  'overH',
                  Math.floor(
                    ((Date.parse(tmpProducts[j].endTime) - now) /
                      1000 /
                      60 /
                      60) %
                    24
                  )
                )
                this.$set(
                  this.list[j],
                  'overM',
                  Math.floor(
                    ((Date.parse(tmpProducts[j].endTime) - now) / 1000 / 60) % 60
                  )
                )
                this.$set(
                  this.list[j],
                  'overS',
                  Math.floor(
                    ((Date.parse(tmpProducts[j].endTime) - now) / 1000) % 60
                  )
                )
              } else {
                if (!tmpProducts[j].list) return
                for (let i = 0; i < tmpProducts[j].list.length; i++) {
                  if (!tmpProducts[j].list[i].stop) {
                    this.$set(this.list[j].list[i], 'stop', false)
                  }
                }
              }
            }
          }, 1000)
        }
      },
      // 跳转拍卖会
      auctionId(id) {
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: id
          }
        })
      },
      // 跳转拍卖行
      auctionHouseId(id) {
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      },
      // 跳转拍品详情
      product(id) {
        this.$router.push({
          path: '/productdetails',
          name: 'Productdetails',
          query: {
            productdetailsId: id
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  .auctionrecords-view {
    width: 1400px;

    /deep/ .has-gutter {
      width: 1400px;

      border-radius: 100px !important;
    }

    /deep/ .el-table th.el-table__cell {
      background-color: #f5eacc !important;
      color: #666 !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      text-align: center;

      /deep/ .scope-img {
        width: 100px;
      }
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    .acount-view-middle {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;
      display: flex;
      padding: 20px 0;

      .acount-view-middle-1 {
        width: 200px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;

        .active {
          color: #cda156;
          border-bottom: 2px solid #cda156;
        }

        a {
          color: #666;

          &:hover {
            color: #cda156;
            border-bottom: 2px solid #cda156;
          }
        }
      }

      .br {
        border-right: 1px solid #bfc3c4;
      }
    }

    .cjjj-currentBid {
      color: #cda156;
      text-decoration: underline;
    }

    .scope-display {
      display: flex;
      align-items: center;

      .scope-time {
        display: inline-block;
      }

      .scope-time-1 {
        padding-left: 20px;

        .sl-text {
          width: 250px;
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          color: #333;
          text-align: left;
        }
      }
    }

    .scope-button {
      display: flex;
      flex-direction: column;
      align-items: center;

      .red-top {
        width: 140px;

        color: #6e5121;
        font-weight: 600;
        font-size: 16px;
        opacity: 0.69;
        border-radius: 8px;
        background: #cda156;
        height: 38px;
        line-height: 0;
        margin-bottom: 10px;
      }

      .red-bottom {
        width: 140px;
        border: 1px solid #dedede;
        color: #666666;
        font-weight: 600;
        font-size: 16px;
        opacity: 0.69;
        border-radius: 8px;
        height: 38px;
        line-height: 0;
        margin-left: 0;
      }
    }

    .acount-view-bottom {
      width: 100%;
      background: #fff;
      padding: 20px 20px 20px 20px;
    }
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }

  .acount-view-bottom-middle {
    height: 88px;
    background: #fff;
    margin-bottom: 20px;
    padding: 20px;

    .acount-view-bottom-middle-nei {
      background: rgba(245, 234, 204, 0.5);
      height: 48px;
      border-radius: 34px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-size: 18px;
      font-weight: 600;
      color: #666;
    }
  }

  .acount-view-bottom-middle-di-text {
    background: #fff;
    padding: 0 30px;
    margin-bottom: 10px;

    .acount-view-bottom-middle-di-text-top {

      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding-top: 20px;
      padding-bottom: 10px;

      .left-c-z {
        display: flex;
        flex-direction: column;

        >.startTime {
          color: #333333;
          font-size: 20px;
        }

        .wight {
          font-size: 24px;
          font-weight: 500;
          color: #333;

          &:hover {
            text-decoration: underline;
          }
        }

        .wight-2 {
          font-size: 20px;
          font-weight: 400;
          color: #333;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .right-c-z {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        >.zhuanchang {
          color: #CDA156;
          font-size: 20px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          color: #333;
          margin-bottom: 0;
        }

        .cut-off {
          color: #666;
          font-size: 20px;
          padding-top: 20px;
        }

        .pd-off {
          padding-top: 4px;
        }

        .pd-red {
          color: #CD5656 !important;
          font-weight: bold;
          padding-bottom: 30px;
        }
      }
    }

    .acount-view-bottom-middle-di-text-bottom {
      height: 141px;
      padding: 20px 0;
      display: flex;
      // align-items: center;
      border-top: 1px solid #dedede;

      .auction-img {
        display: flex;
        align-items: center;
        width: 424px;

        .col-md-3 {
          width: 100px;
          margin: 0;
          border: none;
          height: none;

          .thumb-container {
            width: 100%;
            // height: 317px;
            position: relative;
            padding-bottom: 100%;
            margin: 0;
            border: none;

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }

        .auction-bo-im {
          margin-left: 10px;

          a {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 250px;
            display: block;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .auction-lot-title {
        font-weight: bold !important;
        margin-bottom: 10px;
      }

      .auction-lot-yuan {
        width: 192px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 20px;
        color: #333;

        .statusOut {
          color: #CD5656;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .auction-lot-chujia {
        display: flex;
        flex-direction: column;
        width: 235px;
        align-items: center;

        // margin-right: 140px;
        span {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }

        a {
          font-size: 16px;
          text-decoration: underline;
          color: #cda156;
          font-weight: 600;
        }
      }

      .auction-lot-jingjia {
        width: 210px;
        font-size: 20px;
        font-weight: 400;
        display: flex;
        align-items: center;
        // justify-content: center;
        flex-direction: column;

        .cda156-1 {
          color: #cda156;
        }

        .c999 {
          color: #999;
        }
      }

      .auction-lot-caozuo {
        font-size: 16px;
        width: 208px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ti {
          width: 140px;
          height: 38px;
          background: #cda156;
          border-radius: 8px;
          margin-bottom: 20px;
          line-height: 28px;
          text-align: center;
          color: #6e5121;
          line-height: 38px;
          display: block;
        }

        .che {
          width: 140px;
          height: 38px;
          color: #666666;
          border: 1px solid #dedede;
          border-radius: 8px;
          margin-bottom: 5px;
          line-height: 28px;
          text-align: center;
          line-height: 38px;
          display: block;
        }
      }
    }
  }
</style>
