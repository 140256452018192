<template>
  <div class="container-personalcenter" id="disableRightClicking">
    <div class="publicpersonalcenter-information">
      <div class="publicpersonalcenter-information-left">
        <div class="publicpersonalcenter-information-top">
          <img :src="[
              image === '' ? require('../../assets/images/25.png') : image
            ]" alt="" />
          <p>{{ name }}</p>
          <span>UID：{{ id }}</span>
          <span>{{ mobile | phoneFilter }}</span>
        </div>
        <div class="publicpersonalcenter-information-middle">
          <ul>
            <li :class="[informationShow === 1 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(1)">我的收藏</a>
            </li>
            <li :class="[informationShow === 4 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(4)">我的账户</a>
            </li>
            <li :class="[informationShow === 5 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(5)">竞拍记录</a>
            </li>
            <li :class="[informationShow === 11 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(11)">成交确认书</a>
            </li>
            <li :class="[informationShow === 6 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(6)">我的订单</a>
            </li>
            <li :class="[informationShow === 7 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(7)">开拍提醒</a>
            </li>
            <li :class="[informationShow === 12 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(12)">品相报告 <span class="circle"
                  v-if="!isReadPhaseReport"></span></a>
            </li>
            <li :class="[informationShow === 8 ? 'active' : '']">
              <a href="javascript:;" @click="toggleInformation(8)">收货地址</a>
            </li>
          </ul>
        </div>
        <div class="publicpersonalcenter-information-bottom">
          <ul>
            <li :class="[informationShow === 2 ? 'active' : '']">
              <a href="javascript:" @click="toggleInformation(2)">个人资料</a>
            </li>
            <li :class="[informationShow === 9 ? 'active' : '']">
              <a href="javascript:" @click="toggleInformation(9)">实名认证</a>
            </li>
            <li :class="[informationShow === 10 ? 'active' : '']">
              <a href="javascript:" @click="toggleInformation(10)">消息通知</a>
            </li>
            <li :class="[informationShow === 3 ? 'active' : '']">
              <a href="javascript:" @click="toggleInformation(3)">意见反馈</a>
            </li>
            <li><a href="javascript:" @click="goOut()">退出登录</a></li>
          </ul>
        </div>
      </div>
      <div class="publicpersonalcenter-information-right">
        <div class="publicpersonalcenter-individuals" v-if="informationShow === 2">
          <div class="publicpersonalcenter-individuals-text">个人基本信息</div>
          <div class="publicpersonalcenter-individuals-avart">
            <span>头像</span>
            <div class="replacement">
              <img :src="[
                  image === '' ? require('../../assets/images/25.png') : image
                ]" alt="" />
              <input type="file" id="file" accept="image/*" @change="handleFile" class="hiddenInput" />
              <span>
                更换头像
              </span>
            </div>
          </div>
          <div class="nicknames">
            <span>昵称</span>
            <span>{{ name }}</span>
            <p @click="editNicknames()">
              <a href="javascript:">编辑</a>
              <i class="iconfont icon-bianji"></i>
            </p>
          </div>
          <div class="nicknames">
            <span>手机号</span>
            <span>{{ mobile }}</span>
            <!-- <p @click="editPhone()">
              <a href="javascript:">编辑</a>
              <i class="iconfont icon-bianji"></i>
            </p> -->
          </div>
          <!-- <div class="nicknames">
            <span>密码</span>
            <span>*************</span>
            <p @click="editPassword()">
              <a href="javascript:">编辑</a>
              <i class="iconfont icon-bianji"></i>
            </p>
          </div> -->
        </div>
        <Collect v-if="informationShow === 1" :total="total" :page="collectlistL.page" :pageSize="collectlistL.pageSize"
          :collectlistData="collectlistData" @userCollectBrow="userCollectBrow" @pageb="pageb" />
        <Attestation ref="attestation" v-if="informationShow === 9" />
        <Receivinggoods ref="receivinggoods" v-if="informationShow === 8" />
        <Account ref="account" @goRealName="goRealName" v-if="informationShow === 4" />
        <!-- 竞拍记录 -->
        <Auctionrecords ref="auctionrecords" v-if="informationShow === 5" />
        <!-- 我的订单 -->
        <Myorder ref="myorder" v-if="informationShow === 6" />
        <Reminder ref="reminder" v-if="informationShow === 7" />
        <!-- 消息通知 -->
        <Message ref="message" v-if="informationShow === 10" />
        <!-- 成交确认书 -->
        <Transaction ref="transaction" v-if="informationShow === 11" />
        <!-- 品相报告 -->
        <quality-management ref="qualityManagement" v-if="informationShow === 12" />
        <!-- 品相报告详情 -->
        <quality-details ref="qualityDetails" @read="userInfo()" v-if="informationShow === 13" />
        <div class="publicpersonalcenter-individuals" v-if="informationShow === 3">
          <div class="publicpersonalcenter-individuals-text">意见反馈</div>
          <div class="publicpersonalcenter-content">
            <span>反馈内容</span>
            <textarea placeholder="请输入您要反馈的内容" v-model="userFeedbackDate.message"></textarea>
          </div>
          <button type="button" @click="userFeedback()">提交</button>
        </div>
      </div>
      <!-- 修改昵称 -->
      <Editnicknames ref="editnicknames" v-on:userUpdateData="showMsgfromChild"></Editnicknames>
      <!-- 修改手机号 -->
      <Editphone ref="editphone"></Editphone>
      <!-- 修改密码 -->
      <Editpassword ref="editpassword"></Editpassword>
    </div>
  </div>
</template>
<script>
  import Editnicknames from './components/Editnicknames.vue'
  import Editphone from './components/Editphone.vue'
  import Editpassword from './components/Editpassword.vue'
  import Collect from './components/Collect.vue'
  import Attestation from './components/Attestation.vue'
  import Receivinggoods from './components/Receivinggoods.vue'
  import Account from './components/Account.vue'
  import Auctionrecords from './components/Auctionrecords.vue'
  import Myorder from './components/Myorder.vue'
  import Reminder from './components/Reminder.vue'
  import Message from './components/Message.vue'
  import Transaction from './components/Transaction.vue'
  import qualityManagement from './components/Qualitymanagement'
  import qualityDetails from './components/Qualitydetails'
  import {
    userFeedback,
    userInfo,
    collectlist,
    uploadFile,
    userUpdate
  } from '../../api/index'
  import OSS from 'ali-oss'
  export default {
    name: 'Personalcenter',
    inject: ['reload'],
    components: {
      Editnicknames,
      Editphone,
      Editpassword,
      Collect,
      Attestation,
      Receivinggoods,
      Account,
      Auctionrecords,
      Myorder,
      Reminder,
      Message,
      Transaction,
      qualityManagement,
      qualityDetails
    },
    data() {
      return {
        informationShow: 2,
        dialogVisible: false,
        userFeedbackDate: {
          message: '',
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        },
        name: '',
        mobile: '',
        image: '',
        collectlistData: [],
        ossToken: {},
        avartImg: '',
        userUpdateData: {
          mobile: '',
          userid: 0,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          name: '',
          image: '',
          password: ''
        },
        collectlistL: {
          userid: '',
          page: 1,
          pageSize: 12
        },
        total: 0,
        id: '',
        isReadPhaseReport: true
      }
    },
    filters: {
      // 手机号分割
      phoneFilter(value) {
        const start = value.slice(0, 3)
        const main = value.slice(3, 7)
        const end = value.slice(7)
        return start + ' ' + main + ' ' + end
      }
    },
    created() {
      if (this.$route.query.fige === 1 || this.$route.query.fige === '1') {
        this.informationShow = 4
      } else {
        if (this.$route.query.informationShow) { // 切换到实名认证选项卡
          this.informationShow = Number(this.$route.query.informationShow)
        } else {
          this.informationShow =
            Number(localStorage.getItem('informationShow')) || 2
        }
      }
      this.userUpdateData.userid = this.$store.state.userId
      this.designates()
      this.userInfo()
      // this.collectlist()
    },
    methods: {
      goRealName() { // 跳转实名
        this.toggleInformation(9)
      },
      // 用户收藏列表初始化数据
      async collectlist() {
        const res = await collectlist(this.collectlistL)
        if (res.code === 0) {
          this.collectlistData = res.data.list
          this.total = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      pageb(data) {
        this.collectlistL.page = data
        this.collectlist()
      },
      showMsgfromChild(data) {
        this.userInfo()
      },
      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      random_string(len) {
        len = len || 32
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
        var maxPos = chars.length
        var pwd = ''
        for (let i = 0; i < len; i++) {
          pwd += chars.charAt(Math.floor(Math.random() * maxPos))
        }
        return pwd
      },
      // 更新用户信息
      async userUpdate() {
        this.userUpdateData.userid = Number(localStorage.getItem('accessId'))
        const res = await userUpdate(this.userUpdateData)
        if (res.code === 0) {
          this.userInfo()
          var timer = setTimeout(() => {
            location.reload()
            clearInterval(timer)
          }, 500)
          this.$message.success('更换成功')
          this.informationShow = 2
        } else {
          this.$message.error(res.msg)
        }
      },
      async handleFile(event) {
        var uploadHost = 'https://pic.easyebid.com'
        await this.uploadFile()
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: this.ossToken.accessKeyId,
          accessKeySecret: this.ossToken.accessKeySecret,
          bucket: 'yideonline',
          stsToken: this.ossToken.securityToken
        })
        // var file = event.target.files[0]
        // const randomName = `${this.random_string(6)}_${file.name}`
        // this.userUpdateData.image = `${uploadHost}/${randomName}`
        var file = event.target.files[0]
        console.log(file)
        const name = file.name.split('.')
        const len = name.length
        var timestamp = Date.parse(new Date())
        const fileName = 'yideava' + timestamp + '.' + name[len - 1]
        const result = await client.put(`avatar/${fileName}`, file)
        this.userUpdateData.image = `${uploadHost}/${result.name}`
        this.userUpdate()
      },
      // 取消收藏后刷新
      userCollectBrow() {
        this.collectlist()
      },
      // 切换
      toggleInformation(index) {
        this.informationShow = index
        localStorage.setItem('informationShow', index)
      },
      // 获取用户信息
      async userInfo() {
        const res = await userInfo({
          userid: Number(this.userFeedbackDate.userid) ||
            Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.name = res.data.name
          this.mobile = res.data.mobile
          this.image = res.data.image
          this.id = res.data.id
          this.isReadPhaseReport = res.data.isReadPhaseReport
          localStorage.setItem('attestation', JSON.stringify(res.data))
        } else {
          this.$message.error(res.msg)
        }
      },
      // 修改昵称
      editNicknames() {
        this.$nextTick(() => {
          this.$refs.editnicknames.open()
        })
      },
      // 修改手机号
      editPhone() {
        this.$nextTick(() => {
          this.$refs.editphone.open()
        })
      },
      // 修改密码
      editPassword() {
        this.$nextTick(() => {
          this.$refs.editpassword.open()
        })
      },
      // 退出登录
      goOut() {
        localStorage.clear()
        this.$message.success('退出成功')
        this.$router.push({
          path: '/homemain',
          name: 'Homemain'
        })
        // this.reload()
        var time = setTimeout(() => {
          location.reload()
          clearInterval(time)
        }, 300)
      },
      // 指定跳转到反馈
      designates() {
        if (this.$route.query.marking) {
          this.informationShow = this.$route.query.marking
          this.$message.info('请填写您的意见反馈~')
        }
      },
      async userFeedback() {
        if (
          this.userFeedbackDate.message === '' ||
          this.userFeedbackDate.message.split(' ').join('').length === 0
        ) {
          this.$message.error('意见反馈不能为空')
          return
        }
        const res = await userFeedback(this.userFeedbackDate)
        if (res.code === 0) {
          this.$message.success('提交成功')
          this.userFeedbackDate.message = ''
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>

<style lang="less" scoped>
  .container-personalcenter {
    background: rgb(248, 248, 248);

    .publicpersonalcenter-information {
      display: flex;
      margin-left: 90px;

      .publicpersonalcenter-information-left {
        .publicpersonalcenter-information-top {
          width: 290px;
          height: 305px;
          background: #fff;
          margin-top: 30px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;

          img {
            width: 130px;
            height: 130px;
            border-radius: 50%;
          }

          p {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            margin-bottom: 0;
          }

          span {
            font-size: 16px;
            font-weight: 400;
            color: #666;
          }
        }

        .publicpersonalcenter-information-middle {
          width: 290px;
          height: 322px;
          background: #fff;
          box-sizing: border-box;
          padding: 20px 0;
          margin-bottom: 20px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 400;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              position: relative;

              &:hover {

                // border-left: 4px solid #cda156;
                font-weight: 600;

                &::before {
                  display: block;
                  content: '';
                  width: 4px;
                  height: 24px;
                  background: #cda156;
                  position: absolute;
                  left: 0;
                }
              }

              a {
                color: #333;
                position: relative;
              }

              .circle {
                width: 7px;
                height: 7px;
                background: #E14632;
                border-radius: 50%;
                right: -10px;
                top: 4px;
                position: absolute;
              }
            }

            .active {
              // border-left: 4px solid #cda156;
              font-weight: 600;

              &::before {
                display: block;
                content: '';
                width: 4px;
                height: 24px;
                background: #cda156;
                position: absolute;
                left: 0;
              }
            }
          }
        }

        .publicpersonalcenter-information-bottom {
          width: 290px;
          height: 218px;
          background: #fff;
          box-sizing: border-box;
          padding: 20px 0;
          margin-bottom: 20px;

          ul {
            height: 100%;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            font-weight: 400;

            li {
              display: flex;
              align-items: center;
              justify-content: center;
              flex: 1;
              position: relative;

              a {
                color: #333;
              }
            }

            li:hover {
              //border-left: 4px solid #cda156;
              font-weight: 600;

              &::before {
                display: block;
                content: '';
                width: 4px;
                height: 24px;
                background: #cda156;
                position: absolute;
                left: 0;
              }
            }

            .active {
              //border-left: 4px solid #cda156;
              font-weight: 600;

              &::before {
                display: block;
                content: '';
                width: 4px;
                height: 24px;
                background: #cda156;
                position: absolute;
                left: 0;
              }
            }
          }
        }
      }

      .publicpersonalcenter-information-right {
        margin-top: 30px;
        width: 1400px;
        // height: 622px;
        margin-bottom: 60px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 30px;

        .publicpersonalcenter-individuals {
          // margin-left: 50px;
          background: #fff;
          padding: 40px 70px 50px 70px;
        }

        .publicpersonalcenter-individuals-text {
          font-size: 36px;
          font-weight: 600;
          color: #333;
        }

        .publicpersonalcenter-content {
          display: flex;
          margin-top: 30px;

          span {
            font-size: 20px;
            color: #333;
            font-weight: 600;
          }

          textarea {
            resize: none;
            outline: none;
            width: 1060px;
            height: 400px;
            margin-left: 20px;
            padding: 10px 0 0 10px;
          }
        }

        button {
          font-weight: 600;
          font-size: 20px;
          width: 260px;
          color: #ffffff;
          height: 50px;
          background: #cda156;
          border-radius: 8px;
          border: none;
          margin-left: 100px;
          margin-top: 40px;
        }

        .publicpersonalcenter-individuals-avart {
          margin-top: 60px;
          padding-bottom: 35px;
          display: flex;

          span {
            font-size: 20px;
            font-weight: 400;
            color: #333;
          }

          .replacement {
            margin-left: 70px;
            position: relative;
            width: 150px;
            height: 113px;
            overflow: hidden;

            span {
              position: absolute;
              font-size: 16px;
              font-family: sans-serif;
              left: 0;
              bottom: 0;
              z-index: 1;
              color: #fff;
              display: block;
              width: 100%;
              height: 63px;
              text-align: center;
              line-height: 60px;
              background: rgba(0, 0, 0, 0.2);
            }

            img {
              width: 100%;
              height: 100%;
            }

            // 隐藏’未选择任何文件‘
            input[type='file'] {
              color: transparent;
            }

            input {
              width: 100%;
              position: absolute;
              height: 63px;
              font-size: 12px;
              font-family: sans-serif;
              left: 0;
              bottom: 0;
              opacity: 0;
              z-index: 100;
            }
          }
        }

        .nicknames {
          display: flex;
          padding: 34px 0;
          align-items: center;
          border-top: 1px solid #dedede;
          text-align: left;

          span:nth-child(1) {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            width: 70px;
          }

          span:nth-child(2) {
            font-size: 16px;
            font-weight: 400;
            color: #333;
            padding-left: 70px;
          }

          p {
            margin-left: auto;

            a {
              color: #6e5121;
              font-size: 16px;
              margin-left: auto;
              padding-right: 10px;
            }

            i {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
</style>
