<template >
  <div class="view-transaction">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="auctionName" label="拍卖会名称" align="center">
      </el-table-column>
      <el-table-column prop="auctionHouseName" label="拍卖行名称" align="center">
      </el-table-column>
      <el-table-column prop="sendTime" label="发送时间" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <a href="javascript:;" class="look" @click="handleLook(scope.row.outTradeNo)">查看</a>
        </template>
      </el-table-column>
    </el-table>
    <!-- 成交确认书 -->
    <deal-dialog ref="dealDialog" :confirmationLetterId='confirmationLetterId' @sendOk='sendOk' />
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="pageTotal"
        :current-page="userTradeOrderInfoListData.page" :page-size="userTradeOrderInfoListData.pageSize"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>

</template>
<script>
import { userTradeOrderInfoList } from '../../../api/index'
import DealDialog from '../../Auctionpersonalcenter/Previewlot/components/Dialog/Dealdialog'
export default {
  name: 'transaction',
  components: {
    DealDialog
  },
  data () {
    return {
      tableData: [],
      userTradeOrderInfoListData: {
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        userId: Number(localStorage.getItem('accessId')),
        page: 1,
        pageSize: 15
      },
      confirmationLetterId: {
        trandNo: '',
        name: '查看User'
      },
      pageTotal: 0
    }
  },
  created () {
    this.userTradeOrderInfoList()
  },
  methods: {
    // 初始化数据
    async userTradeOrderInfoList () {
      const res = await userTradeOrderInfoList(this.userTradeOrderInfoListData)
      if (res.code === 0) {
        this.tableData = res.data.list
        this.pageTotal = res.data.count
      }
    },
    // 分页
    handlePageChange (val) {
      this.userTradeOrderInfoListData.page = val
      this.userTradeOrderInfoList()
    },
    sendOk () {
      this.userTradeOrderInfoList()
    },
    // 查看
    handleLook (id) {
      this.$nextTick(() => {
        this.$refs.dealDialog.open()
      })
      this.confirmationLetterId.trandNo = id
    }
  }
}
</script>
<style lang="less" scoped>
.view-transaction {
  width: 100%;
  background: #fff;
  padding: 30px 20px 0 20px;
  height: 885px;
  .pagination {
    margin-top: 20px;
    text-align: right;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #cda156;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #cda156;
  }
  /deep/ .el-table th.el-table__cell {
    background: rgba(245, 234, 204, 0.5);
  }
  /deep/ .el-table__header-wrapper {
    border-radius: 24px 24px 24px 24px !important;
  }
  /deep/ .el-table th.el-table__cell > .cell {
    font-size: 18px;
    font-weight: 600;
    color: #666;
  }
  /deep/ .el-table .el-table__cell {
    font-size: 18px;
    color: #666;
    font-weight: 600;
  }
  .look {
    font-size: 18px;
    font-weight: 400;
    color: #cda156;
    text-decoration: underline;
  }
}
</style>
