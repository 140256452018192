<template>
  <div class="message-view">
    <div class="acount-view-middle">
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsActive===0?'active':'']" @click="getAccountDetails(0)">全部</a>
      </div>
      <div class="acount-view-middle-1 br ">
        <a href="javascript:;" :class="[getAccountDetailsActive===1?'active':'']" @click="getAccountDetails(1)">未读</a>
      </div>
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsActive===3?'active':'']" @click="getAccountDetails(3)">已读</a>
      </div>
    </div>
    <div class="acount-view-middle-bottom-10">
      <div class="acount-view-middle-bottom-all">
        <div class="checkbox-all">
          <input type="checkbox" @click="checkedAll()" v-model='checked'>
          <span style="padding-left:10px;">全选</span>
        </div>
        <a href="javascript:;" class="delete-a" @click="allDel()">
          删除
        </a>
      </div>
      <div class="acount-view-middle-bottom-all-bottom">
        <ul class="acount-view-middle-bottom-all-bottom-ul">
          <li v-for="(item,index) in unreadMessageList" :key="index">
            <div class="li-1">
              <input type="checkbox" v-model="checkboxModel" :value="item.id">
              <div class="ydd-d" v-if="item.status === '1'"></div>
              <a href="javascript:;" @click="messageInfo(item.id)"
                :class="[item.status === '3'?'messageDDD666':'messageDDD']">{{item.message}}</a>
            </div>
            <div class="li-2">
              <span :class="[item.status === '3'?'li2-2':'li2-1']">{{item.createTime}}</span>
              <a href="javascript:;" class="li2-1" style="color:#cda156;padding-left:100px;"
                @click="deMessage(item.id)">删除</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="unreadMessageData.page"
        :page-size="unreadMessageData.pageSize" @current-change="handlePageChange">
      </el-pagination>
    </div>

    <el-dialog title="消息通知" :visible.sync="dialogVisible" width="30%">
      <span class="message-font">{{messageInfoData}}</span>
    </el-dialog>
  </div>
</template>
<script>
import { unreadMessage, delUnreadMessage, unreadMessageInfo } from '../../../api/index'
import eventBus from '../../../utils/eventBus'
import { MessageBox } from 'element-ui'
const cityOptions = ['上海', '北京', '广州', '深圳']
export default {
  data () {
    return {
      getAccountDetailsActive: 0,
      isIndeterminate: true,
      checkAll: false,
      checkedCities: ['上海', '北京'],
      cities: cityOptions,
      unreadMessageData: {
        status: 0,
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        page: 1,
        pageSize: 10
      },
      pageTotal: 0,
      unreadMessageList: [],
      deMess: [],
      messageInfoData: '',
      dialogVisible: false,
      checked: '',
      checkboxModel: []
    }
  },
  created () {
    this.unreadMessage()
  },
  watch: { // 深度 watcher
    checkboxModel: {
      handler: function (val, oldVal) {
        if (this.checkboxModel.length === this.unreadMessageList.length) {
          this.checked = true
        } else {
          this.checked = false
        }
        if (this.checkboxModel.length === 0) {
          this.checked = false
        }
      },
      deep: true
    }
  },
  methods: {
    getAccountDetails (index) {
      this.getAccountDetailsActive = index
      this.unreadMessageData.status = index
      this.unreadMessageData.page = 1
      this.unreadMessage()
    },
    // 获取数据列表
    async unreadMessage () {
      const res = await unreadMessage(this.unreadMessageData)
      if (res.code === 0) {
        this.unreadMessageList = res.data.list
        this.pageTotal = res.data.count
        eventBus.$emit('unreadNum', res.data.unread)
      } else {
        this.$message.error(res.msg)
      }
    },
    handlePageChange (val) {
      this.unreadMessageData.page = val
      this.unreadMessage()
    },
    checkedAll () {
      var _this = this
      if (this.checked) { // 实现反选
        _this.checkboxModel = []
      } else { // 实现全选
        _this.checkboxModel = []
        _this.unreadMessageList.forEach(function (item) {
          _this.checkboxModel.push(item.id)
        })
      }
    },
    // 单个删除
    deMessage (id) {
      this.checkboxModel = []
      this.delUnreadMessage(id)
    },
    // 删除
    delUnreadMessage (id) {
      MessageBox.confirm('是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delUnreadMessage({
          id: id.toString(),
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.unreadMessage()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 多选删除
    allDel () {
      if (this.checkboxModel.length === 0) {
        this.$message.error('请选择要删除的通知')
        return
      }
      MessageBox.confirm('是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delUnreadMessage({
          id: this.checkboxModel.toString(),
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.unreadMessage()
        } else {
          this.$message.error(res.msg)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 详情
    async messageInfo (id) {
      const res = await unreadMessageInfo({
        id: id,
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })
      if (res.code === 0) {
        this.dialogVisible = true
        this.messageInfoData = res.data.message
        this.unreadMessage()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.message-view {
  width: 1400px;

  /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #cda156;
  }
  /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #cda156;
  }
  /deep/ .el-dialog__header {
    text-align: center;
  }
  /deep/ .el-dialog__title {
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }

  .message-font {
    font-size: 18px;
    font-weight: 400;
    color: #333;
  }
  .acount-view-middle {
    width: 100%;
    background: #fff;
    margin: 20px 0;
    display: flex;
    padding: 20px 0;
    .acount-view-middle-1 {
      width: 200px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 20px;
      // padding-top: 10px;
      .active {
        color: #cda156;
        border-bottom: 2px solid #cda156;
      }
      a {
        color: #666;
        &:hover {
          color: #cda156;
          border-bottom: 2px solid #cda156;
        }
      }
    }
    .br {
      border-right: 1px solid #bfc3c4;
    }
  }
  .acount-view-middle-bottom-10 {
    padding: 20px;
    background: #fff;
    .acount-view-middle-bottom-all {
      width: 100%;
      height: 68px;
      background: #f5eacc;
      border-radius: 29px;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      align-items: center;
      .delete-a {
        width: 80px;
        height: 30px;
        border-radius: 19px;
        border: 1px solid #6e5121;
        text-align: center;
        line-height: 30px;
        font-size: 20px;
        font-weight: 400;
        color: #6e5121;
      }
      .checkbox-all {
        display: flex;
        font-weight: 600;
        align-items: center;
        font-size: 20px;
        input {
          width: 20px;
          height: 20px;
        }
      }
    }
    .acount-view-middle-bottom-all-bottom {
      padding-left: 30px;
      .acount-view-middle-bottom-all-bottom-ul {
        li {
          width: 1280px;
          height: 68px;
          border-bottom: 1px solid #dedede;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .li-1 {
            display: flex;
            align-items: center;

            .messageDDD {
              white-space: nowrap; //不换行
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //变成...
              display: inline-block;
              width: 800px;
              padding-left: 10px;
              color: #333;
              font-size: 18px;
              font-weight: 600;
            }
            .messageDDD666 {
              white-space: nowrap; //不换行
              overflow: hidden; //超出隐藏
              text-overflow: ellipsis; //变成...
              display: inline-block;
              width: 800px;
              padding-left: 10px;
              color: #666;
              font-weight: 400;
              font-size: 18px;
            }
            input {
              width: 20px;
              height: 20px;
            }
            .ydd-d {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: #cd5656;
              margin-left: 10px;
            }
          }
          .li-2 {
            .li2-1 {
              font-size: 18px;
              font-weight: 400;
              color: #333;
            }
            .li2-2 {
              font-size: 18px;
              font-weight: 400;
              color: #666;
            }
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
