<template>
  <div>
    <el-dialog title="出价记录" :visible.sync="dialogVisible" width="40%">
      <div class="scroll">
        <div class="bid-record" v-for="(item,index) in Datalist" :key="index">
          <span class="bid-record-flex">{{item.user_name}}</span>
          <div class="bid-record-flex">
            <span v-if="userif === item.userId" style="color:#cda156">我的出价： {{item.offer}}{{item.currency}}</span>
            <span v-else> 出价： {{item.offer}}{{item.currency}}</span>
          </div>
          <span class="bid-record-flex">{{item.offer_time}}</span>
          <div class="bk-k" v-if="item.lead === true" :class="[item.lead === true?'cd5656':'']">
            <template v-if="(bidStatus==2||bidStatus==3||bidStatus==4||bidStatus==5)&&bidWayId==7">落槌价</template>
            <template v-else>领先</template>
          </div>
          <div class="bk-k" v-else>
            出局
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getOfferList
  } from '../../../api/index'
  export default {
    data() {
      return {
        dialogVisible: false,
        getOfferListData: {
          itemId: '',
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 2000
        },
        Datalist: [],
        userif: 0
      }
    },
    props: {
      bidRecordId: {
        type: Number
      },
      bidStatus: '',
      bidWayId: ''
    },
    created() {
      this.userif = Number(localStorage.getItem('accessId'))
    },
    methods: {
      open() {
        this.dialogVisible = true
        this.getOfferList()
      },
      // 出价数据列表
      async getOfferList() {
        this.getOfferListData.itemId = Number(this.bidRecordId)
        const res = await getOfferList(this.getOfferListData)
        if (res.code === 0) {
          this.Datalist = res.data.offerList
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .el-dialog__header {
    text-align: center;
  }

  /deep/ .el-dialog__title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }

  /deep/ .el-dialog__body {
    overflow: hidden;
    height: 600px;
  }

  .scroll {
    height: 500px;
    overflow-y: scroll;

    .bid-record {
      display: flex;
      align-items: center;

      .bid-record-flex {
        // flex: 1;
        height: 91px;
        font-size: 18px;
        color: #333;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 210px;
      }

      .bk-k {
        padding: 0 6px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #666;
        font-size: 18px;
        color: #333;
        font-weight: 400;
        display: flex;
        align-items: center;
      }

      .drop {
        width: 65px !important;
      }

      .cd5656 {
        color: #cd5656;
        border: 1px solid #cd5656;
      }
    }
  }
</style>
