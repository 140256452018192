<template>

  <div ref="refModel">
    <a-modal :getContainer="() => $refs.refModel" title="修改手机号码" :visible="visible" @cancel="handleCancel">

      <a-input placeholder="请输入新手机号码" v-model="newPhone" />

      <div class="code">
        <a-input-search placeholder="请输入验证码" enter-button="获取验证码" size="large" @search="onSearch" v-model="newCode" />
      </div>
      <template slot="footer">
        <div class="ok">
          <a-button @click="handleOk">完成</a-button>

        </div>
      </template>
    </a-modal>
  </div>

</template>
<script>
export default {
  name: 'Editnicknames',
  data () {
    return {
      visible: false,
      newPhone: '',
      newCode: ''
    }
  },

  methods: {
    showModal () {
      this.visible = true
    },
    handleOk (e) {
      if (this.newPhone === '') {
        this.$message.error('请输入新手机号码')
        return
      } else if (this.newCode === '') {
        this.$message.error('请输入验证码')
        return
      }
      this.visible = false
      this.confirmLoading = false
    },
    handleCancel (e) {
      this.visible = false
    },
    open () {
      this.visible = true
    },
    onSearch (value) {

    }
  }

}
</script>
<style lang="less" scoped>
.container-editnicknames {
  /deep/ .ant-modal-header {
    text-align: center;
    border: none;
  }
  /deep/ .ant-modal-footer {
    text-align: center;
    border: none;
  }
  .code {
    margin-top: 30px;
    /deep/ .ant-input {
      width: 200px;
      height: 40px;
      margin-left: 115px;
    }
    /deep/ .ant-input-group-addon {
      background-color: #fff;
    }
    /deep/ .ant-btn {
      color: #fff;
      font-weight: 400;
      font-size: 16px;

      background: #cda156;
      border: none;
    }
    /deep/ .ant-btn {
      width: 120px;
      margin-right: 116px;
    }
  }
  /deep/ .ant-input {
    width: 320px;
    height: 40px;
  }
  /deep/ .ant-input:hover {
    border-color: #cda156;
  }
  /deep/ .ant-input:focus {
    box-shadow: none;
  }
  .ok {
    /deep/ .ant-btn {
      width: 140px;
      height: 50px;
      color: #6e5121;
      font-weight: 600;
      font-size: 20px;
      background: #f5edde;
      border: none;
      margin-top: 20px;
    }
  }

  /deep/ .ant-modal-content {
    width: 600px;
    height: 393px;
  }
  /deep/ .ant-modal-title {
    font-size: 22px;
    margin-top: 30px;
  }
  /deep/ .ant-modal-body {
    margin-top: 20px;
  }
}
</style>
