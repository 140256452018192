<template>
  <div class="reminder-view">
    <div class="bidding-main">
      <div class="bidding-main-for" v-for="(item,index) in userRemindShootingListData" :key="index">
        <div class="col-md-3">
          <div class="thumb-container">
            <div class="thumb">
              <router-link target="_blank" :to="{path:'/auctiondetails',query:{auctiondetailsId:item.id}}">

                <!-- <a href="javascript:;" @click="auctionDetails(item.id)"> -->
                <img :src="item.image" alt="">
                <!-- </a> -->
              </router-link>
            </div>
          </div>
        </div>
        <div class="bidding-main-right">
          <div class="bidding-main-right-left">
            <div class="bidding-collot">
              <router-link target="_blank" :to="{path:'/auctiondetails',query:{auctiondetailsId:item.id}}">
                {{item.chineseTitleAuction}}
              </router-link>

              <!-- <a href="javascript:;" :title="item.chineseTitleAuction" @click="auctionDetails(item.id)">
                {{item.chineseTitleAuction}}
              </a> -->
              <a class="right-collot" @click="tabImgColor(item,index,status)">
                <i :class="[!item.status?'iconfont icon-kongaixin':'iconfont icon-aixin']"
                  style="color:rgb(234, 49, 74)"></i>
                <a href="javascript:;" class="click-collot">收藏</a>
              </a>
              <a class="right-collot" @click="qxStart(item.id)">
                <i class="iconfont " :class="[item.remindStatus ===true?'icon-renwutixing':'icon-duanxintixing']"
                  style="color:rgb(234, 49, 74);font-size:15px;"></i>
                <a href="javascript:;" class="click-collot">{{item.remindStatus===true?'取消提醒':'开拍提醒'}}</a>
              </a>
              <!-- <a href="javascript:;" class="right-collot"
                @click="qxStart(item.id)">{{item.remindStatus === true?'取消提醒':'开拍提醒'}}</a> -->
            </div>
            <router-link target="_blank" style="color:#2c3e50;"
              :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.auctionHouseId}}">
              {{item.auctionHouseTitle}}
            </router-link>
            <!-- <a href="javascript:;" :title="item.auctionHouseTitle" style="color:#2c3e50;"
              @click="auctionhousedetails(item.auctionHouseId)">{{item.auctionHouseTitle}}</a> -->
            <!-- v-if="item.deferment === false" -->
            <p style="margin-top:20px;" v-if="item.deferment === false">
              <span>{{item.startTime}}</span>
              <span>|</span>
              <span>北京时间</span>
            </p>
            <a href="javascript:;"
              style="color:#333;font-size:0.104167rem;display:block;padding-bottom:0.104167rem;text-decoration:underline"
              v-else>延期待定</a>
            <!-- <p v-else>延期待定</p> -->
            <p class="yeelow">
              <span>保证金 {{item.earnestMoney}}</span>
              <span style="padding:0 10px">|</span>
              <span v-if="item.auctionWay === 7" style="color:#CD5656;">直播专场</span>
              <span v-else-if="item.auctionWay === 8" style="color:#cda156;">代拍专场</span>
            </p>
            <p>
              <img src="../../../assets/images/12.png" alt="">
              <span>{{item.location}}</span>
            </p>
          </div>
          <div class="right-browse">

            <span class="bidding-main-right-right">即将开拍</span>

          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="pageTotal"
        :current-page="userRemindShootingListDataR.page" :page-size="userRemindShootingListDataR.pageSize"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    userRemindShootingList,
    emindShooting,
    collectAuction
  } from '../../../api/index'
  export default {
    name: 'ReminderAuction',
    data() {
      return {
        userRemindShootingListData: [],
        userRemindShootingListDataR: {
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10
        },
        pageTotal: 0,
        collotStatus: null
      }
    },
    created() {
      this.userRemindShootingList()
    },
    methods: {
      // 获取数据列表
      async userRemindShootingList() {
        const res = await userRemindShootingList(this.userRemindShootingListDataR)
        if (res.code === 0) {
          this.userRemindShootingListData = res.data.list
          this.pageTotal = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      // 分页
      handlePageChange(page) {
        this.userRemindShootingListData.page = page
        this.userRemindShootingList()
      },
      async qxStart(id) {
        const res = await emindShooting({
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: Number(id)
        })
        if (res.code === 0) {
          this.$message.success(res.data.status?'系统将在拍卖会开始前1小时提醒您及时参与竞拍':'取消成功')
          this.userRemindShootingList()
        } else {
          this.$message.error(res.msg)
        }
      },
      // 收藏
      tabImgColor(item, index) {
        this.userRemindShootingListData[index].status = !this.userRemindShootingListData[index].status
        this.biddingCollot(item.id)
      },
      // 收藏
      async biddingCollot(id) {
        const res = await collectAuction({
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: Number(id)
        })
        if (res.code === 0) {
          if (res.data.status === false) {
            this.$message.error('取消成功')
          } else {
            this.$message.success('收藏成功')
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 跳转拍卖行详情
      auctionhousedetails(id) {
        sessionStorage.setItem('informationShow', 7)
        this.$router.push({
          path: '/auctionhousedetails',
          name: 'Auctionhousedetails',
          query: {
            auctionhousedetailsid: id
          }
        })
      },
      // 跳转拍卖会详情
      auctionDetails(id) {
        sessionStorage.setItem('informationShow', 7)
        this.$router.push({
          path: '/auctiondetails',
          name: 'Auctiondetails',
          query: {
            auctiondetailsId: id
          }
        })
      },
      // 分页
      handlePageChange(page) {
        this.userRemindShootingListDataR.page = page
        this.userRemindShootingList()
      }
    }
  }
</script>
<style lang="less" scoped>
  .reminder-view {
    width: 1400px;

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    .bidding-main {

      // margin-top: 20px;
      .bidding-main-right:hover {
        box-shadow: none;
      }

      .col-md-3:hover {
        box-shadow: none;
      }

      .bidding-main-for {
        background: #fff;
        display: flex;
        margin-bottom: 20px;

        .forBackgroundImage {
          background-size: 80% !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
          width: 452px;
          // z-index: -1;
          margin: 0;
          opacity: 1;
          // height: 100%;
        }

        .col-md-3 {
          width: 480px;
          margin: 0;
          border: none;
          height: none;

          .thumb-container {
            width: 100%;
            height: 317px;
            position: relative;
            // padding-bottom: 100%;
            margin: 0;
            border: none;

            .thumb {
              position: absolute;
              width: 100%;
              height: 100%;
              text-align: center;
              margin: 0;
              border-top: none;
              border-bottom: none;
              border-left: none;

              a {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;

                img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;
                }
              }
            }
          }
        }

        .bidding-main-right {
          width: 1320px;
          height: 100%;
          margin: 0;
          border: none;
          box-sizing: border-box;
          padding: 0px 30px 0 30px;
          display: flex;
          justify-content: space-between;

          .bidding-main-right-left {
            margin: 0;
            border: none;
            box-shadow: none;
            flex-direction: column;
            text-align: left;
            margin-top: 40px;

            // justify-content: center;
            p {
              // margin-top: 20px;
            }

            .yeelow {
              // color: #cda156 !important;
            }

            .bidding-collot {
              margin: 0;
              border: none;
              padding-bottom: 20px;
              align-items: center;
              display: flex;

              &:hover {
                box-shadow: none;
              }

              a:nth-child(1) {
                // margin-top: 40px;
                // width: 900px;
                width: 350px;
                font-size: 24px;
                font-weight: 500;
                color: #333;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: block;

                &:hover {
                  text-decoration: underline;
                }
              }

              .right-collot {
                // width: 80px;
                height: 24px;
                border: 1px solid #cda156;
                opacity: 0.7;
                border-radius: 4px;
                background: #ffffff;
                margin: 0 0 0 20px;
                display: block;
                line-height: 21px;
                text-align: center;
                color: #cda156;
                padding: 0 10px;

                &:hover {
                  box-shadow: none;
                  text-decoration: none;
                }

                i {
                  font-size: 14px;
                }

                .click-collot {
                  font-size: 14px;
                  color: #cda156;
                  padding-bottom: 0;
                  padding-left: 4px;

                  &:hover {
                    text-decoration: none;
                  }
                }
              }
            }

            a:nth-child(2) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
              padding-bottom: 20px;

              &:hover {
                text-decoration: underline;
              }
            }

            p:nth-child(3) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              span:nth-child(2) {
                padding: 0 10px;
              }
            }

            p:nth-child(4) {
              font-size: 20px;
              font-weight: 400;
              color: #666;
            }

            p:nth-child(5) {
              font-size: 20px;
              font-weight: 400;
              color: #666;

              img {
                width: 14px;
                height: 20px;
                margin-right: 10px;
                transform: translateY(-2px);
              }
            }
          }

          .right-browse {
            margin: 0;
            border: none;
            flex-direction: column;
            display: flex;
            align-items: center;

            &:hover {
              box-shadow: none;
            }

            .bidding-main-right-right {
              display: flex;
              margin: 0;
              border: none;
              box-shadow: none;
              width: 280px;
              height: 44px;
              background: #cda156;
              border-radius: 8px;
              opacity: 0.7;
              color: #6e5121;
              justify-content: center;
              align-items: center;
              font-size: 18px;
              margin-top: 40px;
            }

            .start-bidding {
              padding-top: 20px;
              font-size: 18px;
              color: #cda156;
            }
          }
        }
      }
    }

    .pagination {
      margin-top: 20px;
      text-align: center;
    }
  }
</style>
