<template>
  <div class="quality-details">
    <div class="acount-view-bottom">
      <div class="acount-view-bottom-order-bottom">
        <div class="acount-view-bottom-order-img">
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{
                    path: '/productdetails',
                    query: { productdetailsId: getPhaseReportDetailData.itemId }
                  }">
                  <img :src="getPhaseReportDetailData.itemImg" alt="" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="acount-view-bottom-order-img-right">
          <div class="dropHammerPrice">
            <router-link target="_blank" class="my-price-9" :to="{
                path: '/productdetails',
                query: { productdetailsId: getPhaseReportDetailData.itemId }
              }">
              {{ getPhaseReportDetailData.itemName }}
            </router-link>
            <router-link style="display: block;margin-bottom: 0.052083rem;" target="_blank" :to="{
                path: '/auctionhousedetails',
                query: {
                  auctionhousedetailsid: getPhaseReportDetailData.auctionHouseId
                }
              }" class="co-n">
              拍卖行：{{ getPhaseReportDetailData.auctionHouseName }}
            </router-link>
            <p class="co-n">
              开拍时间：{{ getPhaseReportDetailData.updateTime }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="details-middle">
      <div class="account-for">
        <p class="text-1">品相说明:</p>
        <p class="text-2">
          {{ getPhaseReportDetailData.phaseReport }}
        </p>
      </div>
      <div class="quality-img">
        <p class="text-1">细节图:</p>
        <div class="detail-drawing">
          <viewer :images="image" style="display:flex;width:100%">
            <div class="acount-view-bottom-order-img" v-for="(item, index) in image" :key="index">
              <div class="col-md-3">
                <div class="thumb-container">
                  <div class="thumb">
                    <a href="javascript:;">
                      <img :src="item+imgSize(480)" :data-source="item" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </viewer>
        </div>
      </div>
    </div>
    <div class="quality-bottom">
      <p class="text-1">补充需求:</p>
      <div class="supplements">
        <textarea class="textarea-t" placeholder="如需补充品相报告资料在此提交需求。"
          v-model="getPhaseReportDetailData.otherRequirements"></textarea>
        <div class="btn-tt">
          <button @click="supplementary()">提交补充需求</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    getPhaseReportDetail,
    addPhaseReport
  } from '../../../api/index'
  let timer = null
  export default {
    data() {
      return {
        getPhaseReportDetailData: {},
        image: []
      }
    },
    created() {
      this.getPhaseReportDetail()
    },
    mounted() {
      document.querySelector('.detail-drawing').oncontextmenu = () => { // 细节图禁止右键
        return false
      }
      timer = setInterval(() => { // 监听放大预览图禁止右键
        if (!document.querySelector('.viewer-canvas').firstChild) return
        document.querySelector('.viewer-canvas').firstChild.oncontextmenu = () => {
          return false
        }
      }, 300)
    },
    destroyed() {
      clearInterval(timer)
    },
    methods: {
      imgSize(sizeW, sizeh) {
        return `?x-oss-process=image/resize,m_pad,w_${sizeW},h_${sizeh||sizeW}`
      },
      async getPhaseReportDetail() {
        const res = await getPhaseReportDetail({
          id: +this.$route.query.application
        })
        if (res.data.resultMsg === '成功！') {
          this.getPhaseReportDetailData = res.data
          this.image = this.getPhaseReportDetailData.itemDetailDrawing.split(',')
          this.$emit('read')
        } else {
          this.$message.error(res.msg)
        }
      },
      // 提交补充需求
      async supplementary() {
        if (this.getPhaseReportDetailData.otherRequirements === '') {
          this.$message.error('请填写补充信息')
          return
        }
        const res = await addPhaseReport({
          userId: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken'),
          id: this.getPhaseReportDetailData.id,
          auctionId: this.getPhaseReportDetailData.auctionId,
          itemId: this.getPhaseReportDetailData.itemId,
          specificRequirement: this.getPhaseReportDetailData.specificRequirement,
          otherRequirements: this.getPhaseReportDetailData.otherRequirements,
          itemDetailDrawing: this.getPhaseReportDetailData.itemDetailDrawing.toString(),
          phaseReport: this.getPhaseReportDetailData.phaseReport
        })
        if (res.data.resultCode === 0) {
          this.$message.success(res.data.resultMsg)
          this.getPhaseReportDetail()
        } else {
          this.$message.error(res.data.resultMsg)
        }
      }
    }
  }
</script>
<style lang="less" scope>
  .quality-details {
    .acount-view-bottom {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;

      // padding-bottom: 20px;
      .acount-view-bottom-order-top {
        height: 111px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #dedede;
        padding-left: 20px;

        .acount-view-order-1 {
          font-size: 24px;
          font-weight: 600;
          color: #333;
          // padding-bottom: 10px;
        }

        .acount-view-order-2 {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .acount-view-bottom-order-middle {
        padding-left: 20px;
        padding-top: 15px;

        .acount-view-bottom-order-bottom-name {
          padding-bottom: 20px;
          border-bottom: 1px solid #dedede;

          // align-items: center;
          .myorder-name {
            margin-bottom: 12px;

            .myorder-name-2 {
              font-size: 18px;
              color: #666;
              font-weight: 400;
            }
          }

          .myorder-name-1 {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            padding-right: 10px;
          }

          .myorder-name-2 {
            font-size: 20px;
            font-weight: 400;
            color: #333;
            padding-right: 10px;
          }

          .myorder-loaction {
            display: flex;
            justify-content: space-between;
            padding-right: 30px;

            .myorder-loaction-right {
              display: inline-block;
              width: 140px;
              border: 1px solid #dedede;
              opacity: 0.69;
              border-radius: 8px;
              height: 38px;
              color: #666;
              font-size: 16px;
              text-align: center;
              line-height: 38px;
            }
          }
        }
      }

      .acount-view-bottom-order-bottom {
        padding-left: 20px;
        padding-top: 29px;
        display: flex;
        padding-bottom: 49px;
        border-bottom: 1px solid #dedede;
        align-items: center;

        .acount-view-bottom-order-img {
          width: 160px;
          height: 160px;

          .col-md-3 {
            width: 100%;
            margin: 0;
            border: none;
            height: none;

            .thumb-container {
              width: 100%;
              // height: 317px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }
        }

        .acount-view-bottom-order-img-right {
          width: 1100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 15px;

          .dropHammerPrice {
            width: 700px;
          }

          p {
            margin-bottom: 10px;
          }

          .co-n {
            font-size: 18px;
            color: #333;
            font-weight: 400;
          }

          .my-price-9 {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            margin-bottom: 0.052083rem;
            display: inline-block;

            &:hover {
              text-decoration: underline;
            }
          }

          .cda156-a-1 {
            color: #cda156;
            text-decoration: underline;
            padding-left: 15px;
            font-size: 16px;
          }

          .qx-or-wy {
            font-size: 16px;
            font-weight: 500;
            line-height: 158px;

            .look-quality {
              font-size: 20px;
              font-weight: 600;
              color: #cda156;
              text-decoration: underline;
            }

            .send-application {
              text-decoration: none;
              color: #333333;
              font-weight: 400;
              font-size: 20px;
            }
          }
        }
      }

      .acount-view-bottom-order-count {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 30px;
        align-items: center;
        height: 84px;

        p {
          margin-bottom: 0;
        }

        .jsmx-price {
          font-size: 20px;
          color: #6e5121;
          font-weight: 400;
          text-decoration: underline;
        }

        .count-price-2 {
          margin: 0 30px;

          .hj333 {
            color: #333;
            font-size: 18px;
          }

          .hj-rmb {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }

      .jsmx-count {
        display: inline-block;
        width: 200px;
        color: #6e5121;
        font-weight: 600;
        font-size: 18px;
        opacity: 0.69;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        text-align: center;
        line-height: 44px;
      }

      .jsmx-count-coc {
        color: #999999;
        background: #dedede;
        border: none;
      }
    }

    .text-1 {
      font-size: 24px;
      font-weight: 600;
      color: #333;
      padding-bottom: 10px;
      margin-bottom: 0;
    }

    .quality-bottom {
      margin-top: 10px;
      width: 100%;
      background: #fff;
      padding: 20px 30px 30px 30px;

      .supplements {
        .textarea-t {
          resize: none;
          width: 100%;
          height: 265px;
          padding: 18px 0 0 20px;
          outline: none;
          font-size: 16px;
        }

        .btn-tt {
          margin-top: 20px;
          text-align: center;

          button {
            width: 280px;
            height: 44px;
            background: rgba(206, 161, 102, 0.7);
            border-radius: 8px;
            font-size: 18px;
            color: #6e5121;
            font-size: 18px;
            line-height: 44px;
            border: none;
          }
        }
      }
    }

    .details-middle {
      width: 100%;
      background: #fff;
      margin-top: 10px;
      padding: 20px 30px 10px 30px;

      p {
        margin-bottom: 0;
      }

      .account-for {
        border-bottom: 1px solid #dedede;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .text-2 {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .quality-img {
        .detail-drawing {
          display: flex;
          align-items: center;

          >div {
            flex-wrap: wrap;

            >div {
              margin-bottom: 20px;
            }
          }

          .acount-view-bottom-order-img {
            margin-right: 20px;
            width: 160px;
            height: 160px;

            .col-md-3 {
              width: 100%;
              margin: 0;
              border: none;
              height: none;

              .thumb-container {
                width: 100%;
                // height: 317px;
                position: relative;
                padding-bottom: 100%;
                margin: 0;
                border: none;

                .thumb {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  margin: 0;
                  border-top: none;
                  border-bottom: none;
                  border-left: none;

                  a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;

                    img {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
