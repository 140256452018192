<template>
  <div class="acount-view">
    <div class="acount-view-top">
      <div class="acount-view-top-1">
        <div class="acount-view-top-1-left">
          <span class="my-acount">我的账户</span>
          <span class="iconfont icon-liulan my-left"></span>
        </div>
        <div class="acount-view-top-1-right" v-if="getAccountDetailsDataList.accountStatus==0">
          <div class="acount-view-top-1-right-c">
            <a href="javascript:;" class="fff" @click="payMonay(1)">充值</a>
          </div>
          <div class="acount-view-top-1-right-c acount-view-top-1-right-fff">
            <a href="javascript:;" class="fff-two" @click="payMonay(2)">提现</a>
          </div>
        </div>
      </div>
      <div class="acount-view-top-2">
        <div class="acount-view-top-pirce br">
          <span class="pirce-333">账户总余额（人民币）</span>
          <span class="pirce-600">{{getAccountDetailsDataList.totalBalance}}</span>
        </div>
        <div class="acount-view-top-pirce br">
          <span class="pirce-333">可用总余额（人民币）</span>
          <span class="pirce-600">{{getAccountDetailsDataList.availableAvailableBalance}}</span>
        </div>
        <div class="acount-view-top-pirce">
          <span class="pirce-333">保证金（人民币）</span>
          <span class="pirce-600">{{getAccountDetailsDataList.bond}}</span>
        </div>
      </div>
    </div>
    <div class="acount-view-middle">
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow==='全部'?'active':'']"
          @click="getAccountDetails('全部')">全部</a>
      </div>
      <div class="acount-view-middle-1 br ">
        <a href="javascript:;" :class="[getAccountDetailsShow==='充值'?'active':'']"
          @click="getAccountDetails('充值')">充值</a>
      </div>
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow==='提现'?'active':'']"
          @click="getAccountDetails('提现')">提现</a>
      </div>
      <div class="acount-view-middle-1 br">
        <a href="javascript:;" :class="[getAccountDetailsShow==='结算'?'active':'']"
          @click="getAccountDetails('结算')">结算</a>
      </div>
      <div class="acount-view-middle-1">
        <a href="javascript:;" :class="[getAccountDetailsShow==='保证金'?'active':'']"
          @click="getAccountDetails('保证金')">保证金</a>
      </div>
    </div>
    <div class="acount-view-bottom">
      <!-- <div class="acount-view-bottom-y">
        <div class="acount-view-bottom-y-1">交易单号</div>
        <div class="acount-view-bottom-y-1">交易描述</div>
        <div class="acount-view-bottom-y-1">金额(人民币)</div>
        <div class="acount-view-bottom-y-1">支付方式</div>
        <div class="acount-view-bottom-y-1">类型</div>
        <div class="acount-view-bottom-y-1">时间</div>
      </div> -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="outTradeNo" label="交易单号" width="180" align="center">
        </el-table-column>
        <el-table-column prop="description" label="交易描述" width="180" align="center">
        </el-table-column>
        <el-table-column prop="amount" label="金额(人民币)" align="center">
        </el-table-column>
        <el-table-column label="支付方式" align="center">
          <template
            slot-scope="scope">{{scope.row.payType==='1'?'微信':scope.row.payType==='2'?'支付宝':scope.row.payType==='3'?'银联':scope.row.payType==='4'?'余额':scope.row.payType==='5'?'线下支付':''}}</template>

        </el-table-column>
        <el-table-column label="类型" align="center">
          <template slot-scope="scope">
            <span
              v-if="getAccountDetailsShow==='提现'">{{scope.row.resultCode==='0'?'提现待确认':scope.row.resultCode==='200'?'提现成功':''}}</span>
            <span v-else>{{scope.row.type}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="time" label="时间" align="center">
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination background layout="prev, pager, next" :total="pageTotal"
          :current-page="getAccountDetailsData.page" :page-size="getAccountDetailsData.pageSize"
          @current-change="handlePageChange">
        </el-pagination>
      </div>
    </div>
    <!-- 充值 -->
    <el-dialog title="充值" :visible.sync="dialogVisible" width="30%">
      <div class="cz-pay">
        <span class="cz-text">充值金额：</span>
        <input type="text" placeholder="请您输入充值金额" v-model="payCommissionPrice" @blur="changeBlur" class="cz-input">
        <span class="cz-y">元</span>
      </div>
      <div class="cz-pay-zf">
        <div class="cz-pay-zf-1">
          <div class="cz-pay-zf-1-wx">
            <span class="iconfont icon-weixinzhifu" style="color:rgb(0, 179, 45);padding-right:5px;"></span>
            <span>微信</span>
          </div>
          <div class="cz-pay-zf-1-wx">
            <span class="iconfont icon-zhifubao" style="color:rgb(0, 161, 233);padding-right:5px;"></span>
            <span>支付宝</span>
          </div>
        </div>
        <div class="dialog-right">
          <el-radio-group v-model="radio" @change="wxPay($event)">
            <el-radio label="1"></el-radio>
            <el-radio label="2"></el-radio>
          </el-radio-group>
        </div>

      </div>
      <div class="payCode" v-if="radio === '1' || radio==='2'">
        <!-- <img :src="url" alt=""> -->
        <ewmCode ref="ewmCode" />
        <span>支付完成后请点击下方支付保证金按钮</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="payLoad()">立即支付</el-button>
        <div class="dialog-2 ">
          <el-checkbox v-model="checked"></el-checkbox>

          <div class="dialog-1-2">
            <p class="dialog-padding">我已同意<a href="javascript:;" style="color:#cda156;"
                @click="termagreement">《充值条款与协议》</a></p>
          </div>
        </div>
      </span>
    </el-dialog>
    <!-- 提现 -->
    <el-dialog title="提现" :visible.sync="dialogVisibleTx" width="35%">
      <div class="cz-pay" style="border-bottom:none">
        <span class="cz-text">提现金额：</span>
        <input type="text" placeholder="请您输入提现金额" @input="inputnum" v-model="txMonayQ" class="cz-input">
        <span class="cz-y">元</span>
      </div>
      <div class="ti-pay">
        可提现金额{{getAccountDetailsDataList.availableAvailableBalance}}元
        <span class="all-withdrawals"
          @click="txMonayQ=formatDel(getAccountDetailsDataList.availableAvailableBalance)">全部提现</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="txLoad()">确认提现</el-button>
        <div class="dialog-2 ">
          <div class="dialog-1-2">
            <p class="dialog-padding">
              <span class="iconfont icon-tishishuoming" style="color:#cda156;"></span>
              审核通过后，我们将在7个工作日内将金额返还至您的账户，感谢您的惠顾。
            </p>
          </div>
        </div>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisibleT" width="30%">
      <span>请在新打开的页面进行充值。</span>
      <span>充值完成后，根据您的情况点击下面按钮</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="rechargeSuccessful">充值成功</el-button>
        <el-button type="primary" @click="dialogVisibleT = false">充值失败</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="realName" width="18.8%">
      <div class="image-fail">
        <img src="../../../assets/images/failIcon.png" />
      </div>
      <div class="title">您还没有进行实名认证，要去认证吗？</div>
      <span slot="footer" class="dialog-footer">
        <div class="btn-footer">
          <span @click="realName = false">取消</span>
          <span @click="goRealName()">去认证</span>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getAccountDetails,
  weChatRecharge,
  aliRecharge,
  weChatRefund,
  withdrawalOrder
} from '../../../api/index'
import ewmCode from '../../../components/ewmCode.vue'

export default {
  components: {
    ewmCode
  },
  data () {
    return {
      tableData: [],
      getAccountDetailsData: {
        type: '全部',
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken'),
        page: 1,
        pageSize: 10
      },
      getAccountDetailsDataList: {},
      pageTotal: 0,
      dialogVisible: false,
      radio: 0,
      radio2: 1,
      dialogVisibleTx: false,
      payCommissionPrice: '',
      url: '',
      outTradeNo: '',
      radioIf: '',
      getAccountDetailsShow: '全部',
      dialogVisibleT: false,
      txMonayQ: null,
      checked: true,
      realName: false
    }
  },
  created () {
    if (this.$route.query.fige === 1 || this.$route.query.fige === '1') {
      this.dialogVisible = true
    }
    this.getAccountDetailsList()
  },
  destroyed () {
    this.$route.query.fige = 0
  },
  watch: {
    payCommissionPrice: {
      handler (newV, oldV) {
        if (this.radio === '1') {
          if (Number(newV) < 0 || Number(oldV) < 0) {
            this.$message.error('输入金额有误')
            return
          }
          if (newV !== '') {
            this.wxFun()
          } else {
            this.radio = ''
          }
        }
      }
    }
  },
  methods: {
    formatDel (num) { // 去除千分号
      return num.toString().replace(/,/gi, '')
    },
    inputnum () { // 正数且最多两位小数
      // 句号转小数点
      this.txMonayQ = this.txMonayQ.replace(/[\u3002]/, '.')
      // 先把非数字的都替换掉，除了数字和.
      this.txMonayQ = this.txMonayQ.replace(/[^\d.]/g, '')
      // //必须保证第一个为数字而不是.
      this.txMonayQ = this.txMonayQ.replace(/^\./, '')
      // 第一位可以为0第二位不能输入数字
      this.txMonayQ = this.txMonayQ.replace(/^0\d/, '0')
      // 保证.只出现一次，而不能出现两次以上
      this.txMonayQ = this.txMonayQ.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      // 只能输入两个小数
      this.txMonayQ = this.txMonayQ.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    goRealName () {
      this.$emit('goRealName')
    },
    // 数据列表
    getAccountDetails (index) {
      this.getAccountDetailsData.page = 1
      this.getAccountDetailsList(index)
      this.getAccountDetailsShow = index
    },
    async getAccountDetailsList (index) {
      this.getAccountDetailsData.type = index || '全部'
      const res = await getAccountDetails(this.getAccountDetailsData)
      if (res.code === 0) {
        this.getAccountDetailsDataList = res.data
        this.tableData = res.data.list
        this.pageTotal = res.data.count
      } else {
        this.$message.error(res.msg)
      }
    },
    // 分页
    handlePageChange (val) {
      this.getAccountDetailsData.page = val
      this.getAccountDetailsList(this.getAccountDetailsShow)
    },
    // 充值提现
    payMonay (index) {
      if (index === 1) {
        // 未实名
        // if (JSON.parse(localStorage.getItem('attestation')).status !== 2) {
        //   this.realName = true
        //   return
        // }
        this.dialogVisible = true
        this.checked = true
        this.payCommissionPrice = ''
        this.radio = 0
      } else if (index === 2) {
        this.dialogVisibleTx = true
        this.txMonayQ = null
      }
    },
    base64ImgtoFile (dataurl, filename = 'file') {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const suffix = mime.split('/')[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime
      })
    },
    async wxFun () {
      const res = await weChatRecharge({
        description: '2',
        amount: Number(this.payCommissionPrice) * 100,
        currency: 'CNY',
        type: 1
      })
      if (res.resultCode === 0) {
        // const img = 'data:image/png;base64,' + res.url
        // const file = this.base64ImgtoFile(img) // 得到File对象
        this.outTradeNo = res.outTradeNo
        this.url = res.url
        this.$nextTick(() => {
          this.$refs.ewmCode.open(this.url)
        })
        // this.url = window.webkitURL.createObjectURL(file) || window.URL.createObjectURL(file) // imgUrl图片网络路径
      } else {
        if (res.resultCode == 5001) { // 未实名
          this.dialogVisible = false
          this.realName = true
        } else {
          this.$message.error(res.resultMsg)
        }
      }
    },
    wxPay (event) {
      const payCommissionPrice = Number(this.payCommissionPrice)
      var y = String(payCommissionPrice).indexOf('.') + 1
      if (payCommissionPrice === 0 || payCommissionPrice < 0 || isNaN(payCommissionPrice) || y > 0) {
        this.$message.error('请输入整数')
        this.radio = 0
        return
      }

      if (event === '1') {
        // 微信支付
        this.wxFun()
      } else if (event === '2') {
        this.alipay()
      }
    },
    changeBlur () {

    },
    rechargeSuccessful () {
      this.$message.success('正在查询中，请稍后...')
      var timeOne = setTimeout(() => {
        this.wxOkSuccess()
        clearInterval(timeOne)
      }, 3000)
      // this.dialogVisibleT = false
      this.dialogVisible = false
    },
    async wxOkSuccess () {
      const res = await weChatRefund({
        outTradeNo: this.outTradeNo
      })
      if (res.resultCode === 0) {
        this.$message.success('充值成功')
        this.dialogVisible = false
        this.getAccountDetailsList()
      } else {
        this.$message.error(res.resultMsg)
      }
    },
    // 支付保证金
    async payLoad () {
      if (this.checked === false) {
        this.$message.error('请您同意协议')
        return
      }
      if (this.radio === 0) {
        this.$message.error('请选择支付方式')
        return
      }
      // if (this.radio === '2') {
      //   const res = await aliRecharge({
      //     subject: '2',
      //     type: 1,
      //     totalAmount: Number(this.payCommissionPrice),
      //     // returnUrl: 'https://www.test.easyebid.com/#/personalcenter?informationShow=4'
      //     returnUrl: 'https://www.easyebid.com/#/personalcenter?informationShow=4'

      //   })
      //   if (res.resultCode === 0) {
      //     this.outTradeNo = res.outTradeNo
      //     window.open(res.url, '_blank')
      //   }
      //   this.dialogVisibleT = true
      // } else
      if (this.radio === '1' || this.radio === '2') {
        this.$message.success('正在查询中，请稍后...')
        var time = setTimeout(() => {
          this.wxOkSuccess()
          clearInterval(time)
        }, 3000)
      }
    },
    // 支付宝支付
    async alipay () {
      const res = await aliRecharge({
        subject: '2',
        type: 1,
        totalAmount: Number(this.payCommissionPrice) * 100,
        // returnUrl: 'https://www.test.easyebid.com/#/personalcenter?informationShow=4'
        returnUrl: 'https://www.easyebid.com/#/personalcenter?informationShow=4'

      })

      if (res.resultCode === 0) {
        this.outTradeNo = res.outTradeNo
        this.url = res.url
        this.$nextTick(() => {
          this.$refs.ewmCode.open(this.url)
        })
        // window.open(res.url, '_blank')
      } else {
        if (res.resultCode == 5001) {
          this.dialogVisible = false // 未实名
          this.realName = true
        } else {
          this.$message.error(res.resultMsg)
        }
      }
      // this.dialogVisibleT = true
    },
    // 确认提现
    async txLoad () {
      const txMonayQ = Number(this.txMonayQ)
      if (txMonayQ === 0 || txMonayQ < 0 || isNaN(txMonayQ) || txMonayQ > Number(this.getAccountDetailsDataList
        .availableAvailableBalance)) {
        this.$message.error('输入金额有误')
        this.radio = 0
        return
      }

      const res = await withdrawalOrder({
        amount: Number(this.txMonayQ)
      })
      if (res.resultCode === 0) {
        this.$message.success('您的提现申请已接收')
        this.dialogVisibleTx = false
        this.getAccountDetailsList()
      } else {
        this.$message.error(res.resultMsg)
      }
    },
    // 条款与协议
    termagreement () {
      this.$router.push({
        path: '/termagreement',
        name: 'Termagreement'
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .acount-view {
    width: 1400px;

    // margin-top: 20px;
    /deep/ .has-gutter {
      width: 1400px;

      border-radius: 100px !important;
    }

    /deep/ .el-checkbox__inner {
      border-color: #b3b0b0;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
    }

    /deep/ .el-dialog__title {
      font-size: 24px;
      font-weight: 600;
    }

    /deep/ .el-dialog__header {
      text-align: center;
    }

    /deep/ .el-table th.el-table__cell {
      background-color: #f5eacc !important;
      color: #666 !important;
      font-size: 18px !important;
      font-weight: 600 !important;
      text-align: center;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    /deep/ .el-radio-group {
      display: flex;
      flex-direction: column;
      text-align: left;
      padding-top: 11px;
    }

    /deep/ .el-radio {
      margin-right: 0;
      padding-bottom: 32px;
    }

    /deep/ .el-radio__input.is-checked .el-radio__inner {
      background: #cda156;
      border-color: #cda156;
    }

    /deep/ .el-radio__inner {
      border-radius: 0;
      border-color: #b3b0b0;
    }

    /deep/ .el-radio__inner:hover {
      border-color: #cda156;
    }

    /deep/ .ant-collapse {
      background-color: #fff;
      border: none;
      position: relative;
    }

    /deep/ .el-radio__label {
      font-size: 12px;
      color: transparent;
      padding-left: 0;
    }

    /deep/ .el-radio__input.is-checked+.el-radio__label {
      color: transparent;
    }

    /deep/ .el-dialog__footer {
      text-align: center;
    }

    /deep/ .el-button {
      width: 200px;
      font-weight: 600;
      font-size: 18px;
      color: #6e5121;
      opacity: 0.69;
      border-radius: 8px;
      background: #cda156;
      height: 44px;
      border: none;
    }

    /deep/ .el-dialog__wrapper {
      z-index: 100 !important;
    }

    .dialog-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #333;
      font-weight: 400;
      padding: 20px 0 0 0;

      .dialog-1-2 {
        display: flex;
        // align-items: center;
        // padding-top: 6px;
        justify-content: center;

        input[type="checkbox"] {
          position: relative;
          cursor: pointer;
          width: 12px;
          height: 12px;
          font-size: 12px;
        }

        input[type="checkbox"]:checked::after {
          position: absolute;
          top: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 12px;
          content: "✓";
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          background-color: #cda156;
          border-radius: 2px;
        }

        .dialog-padding {
          padding-left: 10px;
          font-size: 16px;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .cz-pay {
      height: 70px;
      border-bottom: 1px solid #dedede;

      .cz-text {
        font-size: 20px;
        color: #333;
        font-weight: 600;
        padding-right: 20px;
      }

      .cz-input {
        width: 360px;
        height: 50px;
        font-size: 20px;
        padding-left: 10px;
        outline: none;
        border: 1px solid #dedede;
      }

      .cz-y {
        padding-left: 20px;
        font-size: 20px;
        color: #333;
      }
    }

    .ti-pay {
      padding-left: 120px;

      .all-withdrawals {
        color: #CDA156;
        font-size: 16px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .cz-pay-zf {
      display: flex;
      justify-content: space-between;

      .cz-pay-zf-1 {
        .cz-pay-zf-1-wx {
          margin: 10px 0;

          span {
            font-size: 20px;
            font-weight: 400;
            color: #333;
          }
        }
      }
    }

    .payCode {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 256px;
      }
    }

    .acount-view-top {
      background: #fff;
      height: 192px;

      .acount-view-top-1 {
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #dedede;
        width: 1340px;
        margin: 0 auto;

        .acount-view-top-1-left {
          font-size: 24px;
          color: #333;

          .my-acount {
            font-weight: 600;
          }

          .my-left {
            padding-left: 10px;
            font-size: 24px;
          }
        }

        .acount-view-top-1-right {
          display: flex;

          .acount-view-top-1-right-c {
            width: 100px;
            height: 40px;
            line-height: 40px;
            background: #cda156;
            text-align: center;
            font-size: 20px;
            margin-right: 20px;
            border-radius: 20px;

            .fff {
              color: #fff;
            }
          }

          .acount-view-top-1-right-fff {
            background: #fff;

            border: 1px solid #dedede;

            .fff-two {
              color: #666666;
            }
          }
        }
      }

      .acount-view-top-2 {
        width: 100%;
        display: flex;
        padding-top: 20px;

        .acount-view-top-pirce {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 20px;

          .pirce-333 {
            color: #333;
          }

          .pirce-600 {
            font-size: 24px;
            color: #333;
            font-weight: 600;
          }
        }

        .br {
          border-right: 1px solid #bfc3c4;
        }
      }
    }

    .acount-view-middle {
      width: 100%;
      background: #fff;
      margin: 20px 0;
      display: flex;
      padding: 20px 0;

      .acount-view-middle-1 {
        width: 200px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;

        // padding-top: 10px;
        a {
          color: #666;

          &:hover {
            color: #cda156;
            border-bottom: 2px solid #cda156;
          }
        }

        .active {
          color: #cda156;
          border-bottom: 2px solid #cda156;
        }
      }

      .br {
        border-right: 1px solid #bfc3c4;
      }
    }

    .acount-view-bottom {
      width: 100%;
      background: #fff;
      padding: 20px 20px 20px 20px;

      .pagination {
        margin-top: 20px;
        text-align: right;
      }
    }

    .image-fail {
      margin-bottom: 20px;
      text-align: center;

      >img {
        width: 90px;
        height: 90px;
      }
    }

    .title {
      color: #333333;
      font-size: 18px
    }

    .btn-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      >span {
        cursor: pointer;
        font-size: 18px;
        color: #6E5121;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:first-child {
          width: 140px;
          height: 40px;
          background: #F5EDDE;
        }

        &:nth-child(2) {
          width: 140px;
          height: 40px;
          background: #DCBE8A;
        }
      }
    }
  }
</style>
