import { render, staticRenderFns } from "./Bidrecord.vue?vue&type=template&id=6124a5fd&scoped=true&"
import script from "./Bidrecord.vue?vue&type=script&lang=js&"
export * from "./Bidrecord.vue?vue&type=script&lang=js&"
import style0 from "./Bidrecord.vue?vue&type=style&index=0&id=6124a5fd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6124a5fd",
  null
  
)

export default component.exports