<template>

  <div ref="refModel">
    <a-modal :getContainer="() => $refs.refModel" title="修改昵称" :visible="visible" @cancel="handleCancel">
      <a-input placeholder="请输入新昵称" v-model="userUpdateData.name" />
      <template slot="footer">
        <a-button @click="handleOk">完成</a-button>
      </template>
    </a-modal>
  </div>

</template>
<script>
  import {
    userUpdate
  } from '../../../api/index'
  export default {
    name: 'Editnicknames',
    data() {
      return {
        name: '',
        visible: false,
        userUpdateData: {
          mobile: '',
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          name: '',
          image: '',
          password: ''
        }
      }
    },

    methods: {
      showModal() {
        this.visible = true
      },
      async handleOk(e) {
        if (this.userUpdateData.name === '') {
          this.$message.error('请输入新昵称')
          return
        }
        if (this.userUpdateData.name.length < 4 || this.userUpdateData.name.length > 7) {
          this.$message.error('用户昵称至少4个字并且不得超过7个字')
          return
        }
        const res = await userUpdate(this.userUpdateData)
        if (res.code === 0) {
          this.$emit('userUpdateData', this.userUpdateData)
          this.$message.success('修改成功')
        } else {
          this.$message.error(res.msg)
        }

        this.visible = false
        this.confirmLoading = false
      },
      handleCancel(e) {
        this.visible = false
      },
      open() {
        this.visible = true
      }
    }

  }
</script>
<style lang="less" scoped>
  .container-editnicknames {
    /deep/ .ant-modal-header {
      text-align: center;
      border: none;
    }

    /deep/ .ant-modal-footer {
      text-align: center;
      border: none;
    }

    /deep/ .ant-input {
      width: 440px;
      height: 40px;
    }

    /deep/ .ant-input:hover {
      border-color: #cda156;
    }

    /deep/ .ant-input:focus {
      box-shadow: none;
    }

    /deep/ .ant-btn {
      width: 140px;
      height: 50px;
      color: #6e5121;
      font-weight: 600;
      font-size: 20px;
      background: #f5edde;
      border: none;
      margin-top: 20px;
    }

    /deep/ .ant-modal-content {
      width: 600px;
      height: 300px;
    }

    /deep/ .ant-modal-title {
      font-size: 22px;
      margin-top: 30px;
    }

    /deep/ .ant-modal-body {
      margin-top: 20px;
    }
  }
</style>
