<template>
  <div class="quality-management">
    <div class="acount-view-middle">
      <div class="acount-view-middle-left-10">
        <div class="acount-view-middle-1 br" v-for="(item, index) in headerData" :key="index">
          <a href="javascript:;" :class="{ active: getAccountDetailsIndex === item.id }"
            @click="getAccountDetails(item.id)">{{ item.name }}</a>
        </div>
      </div>
      <div class="acount-view-middle-right-10" v-if="!delShow && getPhaseReportListData.length !== 0">
        <a href="javascript:;" class="right-cad156" @click="management()">管理</a>
      </div>
    </div>
    <div class="acount-view-middle" v-if="delShow" style=" padding-left: 23px;">
      <div class="acount-view-middle-left-10">
        <div class="checkbox-all">
          <input type="checkbox" @click="checkedAll()" v-model="checked" />
          <span style="padding-left:10px;">全选</span>
        </div>
      </div>
      <div class="acount-view-middle-right-11">
        <a href="javascript:;" class="right-cad156" @click="delShow = false">取消</a>
        <a href="javascript:;" class="right-cad156" @click="delManagement()">删除</a>
      </div>
    </div>
    <div class="acount-view-bottom" v-for="(item, index) in getPhaseReportListData" :key="index">
      <div class="acount-view-bottom-order-middle">
        <div class="acount-view-bottom-order-bottom-name">
          <div class="myorder-name">
            <span class="myorder-name-1">{{ item.auctionName }}</span>
          </div>
          <div class="myorder-loaction">
            <div class="myorder-loaction-left myorder-name-2">
              <span style="padding-right:20px">拍卖行:</span>
              <router-link target="_blank" :to="{
                  path: '/auctionhousedetails',
                  query: { auctionhousedetailsid: item.auctionHouseId }
                }" style="color:#01073E">{{ item.auctionHouseName }}</router-link>
            </div>
          </div>
          <div class="myorder-loaction">
            <div class="myorder-loaction-left myorder-name-2">
              <span style="padding-right:20px">开始时间:</span>
              <span>{{ item.startTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="acount-view-bottom-order-bottom" v-for="(item, index) in item.list" :key="index">
        <input type="checkbox" style="margin-right:10px" v-model="checkboxModel" :value="item.id" v-if="delShow" />

        <div class="acount-view-bottom-order-img">
          <div class="col-md-3">
            <div class="thumb-container">
              <div class="thumb">
                <router-link target="_blank" :to="{
                    path: '/productdetails',
                    query: { productdetailsId: item.itemId }
                  }">
                  <img :src="item.itemImg" alt="" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="acount-view-bottom-order-img-right">
          <div class="dropHammerPrice">
            <router-link target="_blank" class="my-price-9" :to="{
                path: '/productdetails',
                query: { productdetailsId: item.itemId }
              }">
              {{ item.itemName }}
            </router-link>
            <p class="co-n">拍品估价：{{ item.assess }}{{ item.currency }}</p>
          </div>
          <div class="qx-or-wy">
            <router-link target="_blank"
              :to="{ path: '/personalcenter', query: { application: item.id,informationShow:13 } }"
              v-if="item.status === 2" class="look-quality" @click.native="sendLook(item.status)">
              查看品相报告
              <span class="circle" v-if="!item.isRead"></span>
            </router-link>
            <a href="javascript:;" class="send-application" v-if="item.status === 1">
              已提交申请</a>
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="page" :page-size="pageSize"
        @current-change="handlePageChange">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    getPhaseReportList,
    delPhaseReport
  } from '../../../api/index'
  export default {
    data() {
      return {
        headerData: [{
            id: 0,
            name: '全部'
          },
          {
            id: 2,
            name: '已发送'
          },
          {
            id: 1,
            name: '未发送'
          }
        ],
        getAccountDetailsIndex: 0,
        getPhaseReportListReference: {
          userId: +localStorage.getItem('accessId'),
          type: 0,
          page: 1,
          pageSize: 5
        },
        getPhaseReportListData: [],
        page: 1,
        pageSize: 10,
        pageTotal: null,
        delShow: false,
        checked: false,
        checkboxModel: [],
        comparison: []
      }
    },
    created() {
      this.getPhaseReportList()
    },
    watch: {
      // 深度 watcher
      checkboxModel: {
        handler: function(val, oldVal) {
          if (this.checkboxModel.length === this.comparison.length) {
            this.checked = true
          } else {
            this.checked = false
          }
          if (this.checkboxModel.length === 0) {
            this.checked = false
          }
        },
        deep: true
      }
    },

    methods: {
      getAccountDetails(id) {
        this.getAccountDetailsIndex = id
        this.getPhaseReportListReference.type = id
        this.getPhaseReportListReference.page = 1
        this.delShow = false
        this.getPhaseReportList()
      },
      // 数据初始化
      async getPhaseReportList() {
        const res = await getPhaseReportList(this.getPhaseReportListReference)
        if (res.data.resultMsg === '成功！') {
          this.getPhaseReportListData = res.data.auctionList
          this.page = res.data.page
          this.pageSize = res.data.pageSize
          this.pageTotal = res.data.count
          for (
            let index = 0; index < this.getPhaseReportListData.length; index++
          ) {
            for (
              let j = 0; j < this.getPhaseReportListData[index].list.length; j++
            ) {
              this.comparison.push(this.getPhaseReportListData[index].list[j].id)
            }
          }
        } else {
          this.$message.error(res.data.resultMsg)
          this.getPhaseReportListData = []
          this.pageTotal = 0
        }
      },
      // 管理
      management() {
        this.delShow = true
      },
      // 分页
      handlePageChange(page) {
        this.getPhaseReportListReference.page = page
        this.getPhaseReportList()
      },
      // 查看品相报告
      sendLook(status) {
        if (status === 1) return
        // localStorage.setItem('informationShow', 13)
      },
      // 全选
      checkedAll() {
        var _this = this
        if (this.checked) {
          // 实现反选
          _this.checkboxModel = []
        } else {
          // 实现全选
          _this.checkboxModel = []
          for (
            let index = 0; index < this.getPhaseReportListData.length; index++
          ) {
            for (
              let j = 0; j < this.getPhaseReportListData[index].list.length; j++
            ) {
              _this.checkboxModel.push(
                this.getPhaseReportListData[index].list[j].id
              )
            }
          }
        }
      },
      // 删除
      async delManagement() {
        if (this.checkboxModel.length === 0) {
          this.$message.error('请选择要删除的报告')
          return
        }
        const res = await delPhaseReport({
          id: this.checkboxModel.toString()
        })
        if (res.data.resultCode === 0) {
          this.$message.success('删除成功')
          this.getPhaseReportListReference.page = 1
          this.getPhaseReportList()
        } else {
          this.$message.error(res.data.resultMsg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .quality-management {
    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156 !important;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    input {
      width: 20px;
      height: 20px;
      -webkit-appearance: none;
      outline: none;
      -webkit-tab-highlight-color: rgba(0, 0, 0, 0);
      vertical-align: middle;
      text-align: center;
      display: inline-block;
      line-height: 20px;
      position: relative;
      border-radius: 50%;
      color: #fff;
      background: #fff;
      border: 1px solid #fff;
    }

    input[type='checkbox']::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: #fff;
      width: 100%;
      height: 100%;
      border: 1px solid #999999;
      border-radius: 50%;
      color: #fff;
      box-sizing: border-box;
    }

    input[type='checkbox']:checked::before {
      content: '\2713';
      background-color: #cda156;
      border: 1px solid #cda156;
      position: absolute;
      top: 0;
      left: 0;

      color: #fff;
      font-size: 10px;
      border-radius: 50%;
    }

    .acount-view-middle {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;
      display: flex;
      padding: 20px 0;
      justify-content: space-between;
      align-items: center;
      padding-right: 30px;

      .acount-view-middle-left-10 {
        display: flex;

        .checkbox-all {
          display: flex;
          font-weight: 600;
          align-items: center;
          font-size: 20px;
        }

        .acount-view-middle-1 {
          width: 200px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 20px;

          // padding-top: 10px;
          a {
            color: #666;

            &:hover {
              color: #cda156;
              border-bottom: 2px solid #cda156;
            }
          }

          .active {
            color: #cda156;
            border-bottom: 2px solid #cda156;
          }
        }

        .br {
          border-right: 1px solid #bfc3c4;
        }
      }

      .acount-view-middle-right-10 {
        width: 90px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 21px;
        border: 1px solid #cda156;

        .right-cad156 {
          font-size: 20px;
          color: #cda156;
        }
      }

      .acount-view-middle-right-11 {
        .right-cad156 {
          display: inline-block;
          width: 90px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 21px;
          border: 1px solid #cda156;
          margin-right: 20px;
          font-size: 20px;
          color: #cda156;
        }
      }
    }

    .acount-view-bottom {
      width: 100%;
      background: #fff;
      margin-bottom: 20px;

      // padding-bottom: 20px;
      .acount-view-bottom-order-top {
        height: 111px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom: 1px solid #dedede;
        padding-left: 20px;

        .acount-view-order-1 {
          font-size: 24px;
          font-weight: 600;
          color: #333;
          // padding-bottom: 10px;
        }

        .acount-view-order-2 {
          font-size: 20px;
          font-weight: 400;
          color: #333;
        }
      }

      .acount-view-bottom-order-middle {
        padding-left: 20px;
        padding-top: 15px;

        .acount-view-bottom-order-bottom-name {
          padding-bottom: 20px;
          border-bottom: 1px solid #dedede;

          // align-items: center;
          .myorder-name {
            margin-bottom: 12px;

            .myorder-name-2 {
              font-size: 18px;
              color: #666;
              font-weight: 400;
            }
          }

          .myorder-name-1 {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            padding-right: 10px;
          }

          .myorder-name-2 {
            font-size: 20px;
            font-weight: 400;
            color: #333;
            padding-right: 10px;
          }

          .myorder-loaction {
            display: flex;
            justify-content: space-between;
            padding-right: 30px;

            .myorder-loaction-right {
              display: inline-block;
              width: 140px;
              border: 1px solid #dedede;
              opacity: 0.69;
              border-radius: 8px;
              height: 38px;
              color: #666;
              font-size: 16px;
              text-align: center;
              line-height: 38px;
            }
          }
        }
      }

      .acount-view-bottom-order-bottom {
        padding-left: 20px;
        padding-top: 29px;
        display: flex;
        padding-bottom: 49px;
        border-bottom: 1px solid #dedede;
        align-items: center;

        .acount-view-bottom-order-img {
          width: 160px;
          height: 160px;

          .col-md-3 {
            width: 100%;
            margin: 0;
            border: none;
            height: none;

            .thumb-container {
              width: 100%;
              // height: 317px;
              position: relative;
              padding-bottom: 100%;
              margin: 0;
              border: none;

              .thumb {
                position: absolute;
                width: 100%;
                height: 100%;
                text-align: center;
                margin: 0;
                border-top: none;
                border-bottom: none;
                border-left: none;

                a {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  max-width: 100%;
                  max-height: 100%;

                  img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
          }
        }

        .acount-view-bottom-order-img-right {
          width: 1100px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-left: 15px;

          .dropHammerPrice {
            width: 700px;
          }

          p {
            margin-bottom: 10px;
          }

          .co-n {
            font-size: 18px;
            color: #333;
            font-weight: 400;
          }

          .my-price-9 {
            font-size: 20px;
            font-weight: 600;
            color: #333;
            margin-bottom: 0.052083rem;
            display: inline-block;

            &:hover {
              text-decoration: underline;
            }
          }

          .cda156-a-1 {
            color: #cda156;
            text-decoration: underline;
            padding-left: 15px;
            font-size: 16px;
          }

          .qx-or-wy {
            font-size: 16px;
            font-weight: 500;
            line-height: 158px;

            .look-quality {
              font-size: 20px;
              font-weight: 600;
              color: #cda156;
              text-decoration: underline;
              position: relative;
            }

            .send-application {
              text-decoration: none;
              color: #333333;
              font-weight: 400;
              font-size: 20px;
            }

            .circle {
              width: 10px;
              height: 10px;
              background: #E14632;
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: 6px;
            }

          }
        }
      }

      .acount-view-bottom-order-count {
        display: flex;
        flex-direction: row-reverse;
        padding-right: 30px;
        align-items: center;
        height: 84px;

        p {
          margin-bottom: 0;
        }

        .jsmx-price {
          font-size: 20px;
          color: #6e5121;
          font-weight: 400;
          text-decoration: underline;
        }

        .count-price-2 {
          margin: 0 30px;

          .hj333 {
            color: #333;
            font-size: 18px;
          }

          .hj-rmb {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }

      .jsmx-count {
        display: inline-block;
        width: 200px;
        color: #6e5121;
        font-weight: 600;
        font-size: 18px;
        opacity: 0.69;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        text-align: center;
        line-height: 44px;
      }

      .jsmx-count-coc {
        color: #999999;
        background: #dedede;
        border: none;
      }
    }

    .pagination {
      margin-top: 20px;
      text-align: center;
    }
  }
</style>
