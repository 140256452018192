<template>
  <div class="container-recommendedIteright">
    <!-- 拍品 -->
    <div class="acount-view-bottom" v-for="(item,index) in remindList" :key="index">
      <div class="recommendedIteright-text-1">
        <div class="recommendedIteright-text-left">
          <router-link target="_blank" class="p1"
            :to="{path:'/auctiondetails',query:{auctiondetailsId:item.auctionId}}">
            {{item.auctionName}}
          </router-link>
          <router-link target="_blank" class="p1 p2"
            :to="{path:'/auctionhousedetails',query:{auctionhousedetailsid:item.auctionHouseId}}">
            {{item.auctionHouseName}}
          </router-link>
          <p class="p2">
            <span>{{item.auctionStartTime}}</span>
            <span class="line-text-1">|</span>
            <span>北京时间</span>
          </p>
        </div>
        <div class="recommendedIteright-text-right">
          即将开始</div>
      </div>

      <div class="recommendedIteright-text-bottom" v-if="listPD===index">
        <div class="recommendedIteright-text-bottom-k-2" v-for="(item,index) in pageList" :key="index">
          <div class="top-img-5">
            <div class="col-md-3">
              <div class="thumb-container">
                <div class="thumb">
                  <router-link target="_blank" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
                    <!-- <a href="javascript:;"> -->
                    <img :src="item.hostImage">
                    <!-- </a> -->
                  </router-link>
                </div>
              </div>
            </div>
            <i :class="[item.status === true?'iconfont icon-aixin ':'iconfont icon-kongaixin']"
              @click="qxCollot(item.id)"></i>
            <div class="status-item" v-if="item.itemAuctionStatus === 3" style="background: #CDA156;">成交</div>
            <div class="status-item" v-if="item.itemAuctionStatus === 4" style="background: #BFC3C4;color: #666666;">
              流拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 5" style="background: #BFC3C4;color: #666666;">
              撤拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 2">直播中</div>
            <div class="status-item" style="background: #CDA156;" v-if="item.itemAuctionStatus === 1">待拍</div>
          </div>

          <div class="top-img-5-bottom">
            <router-link target="_blank" class="lot-h" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
              Lot:{{item.lot}}:{{item.title}}
            </router-link>
            <span class="ppgj-price" style="padding-bottom:10px;">拍品估价：{{item.assess}}</span>
            <span class="qpjg-price" v-if="item.nowPrice === ''">起拍价格：{{item.initialPrice}}{{item.currency}}</span>
            <span class="qpjg-price" v-else>当前价格：{{item.nowPrice}}{{item.currency}}</span>
          </div>
        </div>
      </div>

      <div class="recommendedIteright-text-bottom" v-else-if="listPD !==index">
        <div class="recommendedIteright-text-bottom-k-2" v-for="(item,index) in item.list" :key="index">
          <div class="top-img-5">
            <div class="col-md-3">
              <div class="thumb-container">
                <div class="thumb">
                  <router-link target="_blank" class="lot-h"
                    :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
                    <img :src="item.hostImage">
                  </router-link>
                </div>
              </div>
            </div>
            <i :class="[item.status === true?'iconfont icon-aixin ':'iconfont icon-kongaixin']"
              @click="qxCollot(item.id)"></i>
            <div class="status-item" v-if="item.itemAuctionStatus === 3" style="background: #CDA156;">成交</div>
            <div class="status-item" v-if="item.itemAuctionStatus === 4" style="background: #BFC3C4;color: #666666;">
              流拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 5" style="background: #BFC3C4;color: #666666;">
              撤拍
            </div>
            <div class="status-item" v-if="item.itemAuctionStatus === 2">直播中</div>
            <div class="status-item" style="background: #CDA156;" v-if="item.itemAuctionStatus === 1">待拍</div>
          </div>

          <div class="top-img-5-bottom">
            <router-link target="_blank" class="lot-h" :to="{path:'/productdetails',query:{productdetailsId:item.id}}">
              Lot:{{item.lot}}:{{item.title}}
            </router-link>
            <span class="ppgj-price" style="padding-bottom:10px;">拍品估价：{{item.assess}}</span>
            <span class="qpjg-price"
              v-if="item.nowPrice === '' && item.itemAuctionStatus !== 3">起拍价格：{{item.initialPrice}}{{item.currency}}</span>
            <span class="qpjg-price"
              v-else-if="item.nowPrice !== '' && item.itemAuctionStatus !== 3">当前价格：{{item.nowPrice}}{{item.currency}}</span>
            <span class="qpjg-price"
              v-else-if="item.nowPrice !== '' && item.itemAuctionStatus === 3">落槌价：{{item.nowPrice}}{{item.currency}}</span>
            <span class="qpjg-price"
              v-else-if="item.nowPrice === '' && item.itemAuctionStatus === 3">落槌价：{{item.initialPrice}}{{item.currency}}</span>
          </div>
        </div>
      </div>
      <div class="pagination" @click="auctionPage(item.auctionId,index)"
        v-if="listPD===index || pageListId === item.auctionId">
        <el-pagination background layout="prev, pager, next" :total="item.count"
          :current-page="collectItemlistData.page" @current-change="handlePageChange"
          :page-size="collectItemlistData.pageSize">
        </el-pagination>
      </div>
      <div class="pagination" @click="auctionPage(item.auctionId,index)"
        v-else-if="listPD!==index && pageListId !== item.auctionId">
        <el-pagination background layout="prev, pager, next" :total="item.count" :current-page="collectItemlistDataPage"
          @current-change="handlePageChange" :page-size="collectItemlistDataPageSize">
        </el-pagination>
      </div>
    </div>
    <div class="pagination" style="text-align:center;">
      <el-pagination background layout="prev, pager, next" :total="pageTotal" :current-page="userCollectData.page"
        :page-size="userCollectData.pageSize" @current-change="handlePageChangeP">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    userCollect,
    userLotRemindList,
    userLotRemindItemList
  } from '../../../api/index'
  export default {
    name: 'ReminderGoods',
    data() {
      return {
        userCollectData: {
          userid: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          page: 1,
          pageSize: 10,
          auctionStatus: 0
        },
        pageTotal: 0,
        remindList: [],
        collectItemlistData: {
          userId: Number(localStorage.getItem('accessId')),
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: 0,
          page: 1,
          pageSize: 4
        },
        pageList: [],
        pageListId: '',
        listPD: -1,
        collectItemlistDataPage: 1,
        collectItemlistDataPageSize: 4
      }
    },
    created() {
      this.userLotRemindList()
    },
    methods: {
      // 拍品
      async userLotRemindList() {
        const res = await userLotRemindList(this.userCollectData)
        if (res.code === 0) {
          this.remindList = res.data.list
          this.pageTotal = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      auctionPage(id, index) {
        console.log(index);
        this.collectItemlistData.auctionId = id || 0
        this.pageListId = id
        this.listPD = index
        this.userLotRemindItemList()
        this.userLotRemindList()
      },
      // 拍品分页
      handlePageChange(page) {
        this.collectItemlistData.page = page || 1
      },
      // 拍品拍卖会分页
      handlePageChangeP(page) {
        this.userCollectData.page = page
        this.collectItemlistData.auctionId = 0
        this.auctionPage()
      },
      //收藏
      async qxCollot(id) {
        const res = await userCollect({
          userid: Number(localStorage.getItem('accessId')),
          itemid: id,
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken')
        })
        if (res.code === 0) {
          this.$message.success(res.data.status?'收藏成功':'取消成功')
          this.userLotRemindItemList()
          this.userLotRemindList()
        } else {
          this.$message.error(res.msg)
        }
      },
      async userLotRemindItemList() {
        const res = await userLotRemindItemList(this.collectItemlistData)
        if (res.code === 0) {
          this.pageList = res.data.list
          this.pageListId = res.data.list[0].auctionId
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .container-recommendedIteright {
    width: 1400px;

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background: #cda156;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #cda156;
    }

    .pagination {
      margin-top: 20px;
      text-align: right;
    }



    .acount-view-bottom {
      padding-left: 20px;
      padding-top: 20px;
      background: #fff;
      margin-top: 20px;
      padding-bottom: 20px;
      padding-right: 30px;

      .recommendedIteright-text-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .recommendedIteright-text-left {
          p {
            margin-bottom: 0;
          }

          .p1 {
            font-size: 24px;
            font-weight: 500;
            color: #333;
            padding-bottom: 10px;
            display: block;

            &:hover {
              text-decoration: underline;
            }
          }

          .p3:hover {
            text-decoration: underline;
          }

          .p2 {
            font-size: 20px;
            font-weight: 400;
            color: #666;

            .line-text-1 {
              padding: 0 10px;
            }
          }
        }

        .recommendedIteright-text-right {
          width: 200px;
          height: 44px;
          text-align: center;
          line-height: 44px;
          color: #6e5121;
          font-weight: 600;
          //opacity: 0.69;
          border-radius: 8px;
          background: #cda156;
          background: rgba(205, 161, 86, 0.69);
          font-size: 18px;
        }

        .recommendedIteright-text-right-222 {
          width: 200px;
          height: 44px;
          text-align: center;
          line-height: 44px;
          color: #333;
          font-weight: 600;
          // opacity: 0.69;
          border-radius: 8px;
          //background: #fff;
          background: rgba(255, 255, 255, .69);
          font-size: 18px;
        }
      }

      .recommendedIteright-text-bottom {
        .recommendedIteright-text-bottom-k-2 {
          width: 307px;
          // height: 461px;
          margin-top: 20px;
          box-shadow: 0px 1px 16px 0px rgba(46, 46, 46, 0.05);
          padding-bottom: 30px;
          margin-right: 30px;
          display: inline-block;
          position: relative;

          &:hover {
            box-shadow: 0px 1px 16px 0px rgba(29, 29, 29, 0.05);
          }

          .top-img-5 {
            width: 300px;
            position: relative;

            .status-item {
              width: 80px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              font-size: 18px;
              font-weight: 600;
              background: #d10000;
              color: #fff;
              position: absolute;
              left: 0;
              bottom: 0;
            }

            i {
              position: absolute;
              top: 10px;
              right: 17px;
              font-size: 30px;
              color: rgb(234, 49, 74);
            }

            .col-md-3 {
              margin: 0;
              border: none;
              height: none;
              display: flex;

              .thumb-container {
                width: 100%;
                // height: 405px;
                position: relative;
                padding-bottom: 100%;
                margin: 0;
                border: none;

                &:hover {
                  box-shadow: none;
                }

                .thumb {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  margin: 0;
                  border-top: none;
                  border-bottom: none;
                  border-left: none;

                  // top: 32%;
                  &:hover {
                    box-shadow: none;
                  }

                  a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;

                    img {
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      margin: auto;
                      max-width: 100%;
                      max-height: 100%;
                    }
                  }
                }
              }
            }
          }

          .top-img-5-bottom {
            padding-left: 20px;
            display: flex;
            flex-direction: column;

            .lot-h {
              padding-top: 20px;
              padding-bottom: 10px;
              font-size: 16px;
              color: #333;
              width: 280px;
              // height: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &:hover {
                text-decoration: underline;
              }
            }

            .ppgj-price {
              font-size: 16px;
              font-weight: 400;
              color: #666666;
            }

            .qpjg-price {
              font-size: 16px;
              font-weight: 600;
              color: #333;
            }
          }
        }
      }
    }
  }
</style>
